import { reactive, watch } from 'vue'
import { useVuetify } from '@logue/vue2-helpers/vuetify'

export const useDeviceSize = () => {
  const vuetify = useVuetify()
  let deviceSize = reactive(vuetify.breakpoint)
  watch(() => vuetify.breakpoint.name, () => {
    deviceSize = {
      ...vuetify.breakpoint
    }
  })
  return {
    deviceSize
  }
}
