import { DetailsPanelButtonState } from '@/types/enums'
import { EXPORT, SHARE } from '../icons'
import { ResourceAction } from '@/types/interfaces/global/resource-actions'

export const equipmentActionData: ResourceAction[] = [
  {
    name: '+ Work order',
    validStatuses: [
      'active'
    ],
    primaryAction: [
      'active'
    ]
  },
  {
    name: 'Service request',
    validStatuses: [
      'active'
    ],
    primaryAction: []
  },
  {
    name: 'View Public Profile',
    validStatuses: ['active', 'deleted'],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Hide
  },
  {
    name: 'Edit',
    validStatuses: ['active', 'deleted'],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: '+ Invoice',
    validStatuses: [
      'active'
    ],
    primaryAction: []
  },
  {
    name: '+ Proposal',
    validStatuses: [
      'active'
    ],
    primaryAction: []
  },
  {
    name: 'Decommission',
    textColor: 'error',
    validStatuses: [
      'active'
    ],
    primaryAction: []
  },
  {
    name: 'Recommission',
    validStatuses: [
      'deleted'
    ],
    primaryAction: [
      'deleted'
    ],
    detailsPanel: DetailsPanelButtonState.Expose
  },
  {
    name: 'Save',
    validStatuses: ['active', 'deleted'],
    primaryAction: [],
    detailsPanel: DetailsPanelButtonState.Only
  }
]

export const universalAssetsActionData: ResourceAction[] = [
  {
    name: 'Share',
    validStatuses: true,
    primaryAction: [
    ],
    detailsPanel: DetailsPanelButtonState.Icon,
    buttonIcon: SHARE
  },
  {
    name: 'Export',
    primaryAction: [],
    validStatuses: [
      'active', 'deleted'
    ],
    buttonIcon: EXPORT,
    detailsPanel: DetailsPanelButtonState.Never
  }
]

export const creationAssetsActionData: ResourceAction[] = [
  {
    name: 'Save & Add Asset',
    eventName: 'save',
    validStatuses: true,
    primaryAction: []
  }
]
