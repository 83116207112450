import { api } from '@/api'
import qs from 'qs'
import { snakeCase } from 'lodash'

interface Params {
  include?: string
  q: any
  page: number
  perPage: number
  excludeMailTasks?: boolean
  taskId: number
  cancellableWoCount?: boolean
}
interface CustomReportHeaderInterface {
  jobType: string,
  type: string,
  reportType: string,
  customReportType: string
}

export default {
  get: ({ include, q, page, perPage, excludeMailTasks }:Params) => {
    return api.$get('/background_tasks', {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        exclude_mail_tasks: excludeMailTasks
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getById: (id: string | number, include: string, showInfo?: boolean) => {
    return api.$get(`/background_tasks/${id}/get_stats`, {
      params: { include, showInfo },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  post: (backgroundTaskParams: FormData | any) => {
    let params = backgroundTaskParams
    if (!(backgroundTaskParams instanceof FormData)) {
      params = new FormData()
      const paramKeys = Object.keys(backgroundTaskParams)
      for (let k = 0; k < paramKeys.length; k++) {
        const val = backgroundTaskParams[`${paramKeys[k]}`]
        if (val === undefined || typeof val === 'string') {
          params.append(`background_task[${snakeCase(paramKeys[k])}]`, val)
        } else {
          for (let i = 0; i < val.length; i++) {
            params.append(`background_task[${snakeCase(paramKeys[k])}][]`, val[i])
          }
        }
      }
    }
    return api.$post('/background_tasks',
      params, { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  },

  getCancellableWOs: ({ include, q, page, perPage, taskId, cancellableWoCount }: Params) => {
    return api.$get(`/bulk_cancel_work_orders/${taskId}/cancellable_work_orders`, {
      params: {
        include,
        q,
        page,
        per_page: perPage,
        cancellable_wo_count: cancellableWoCount
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getNonCancellableWOs: ({ include, q, page, perPage, taskId }: Params) => {
    return api.$get(`/bulk_cancel_work_orders/${taskId}/non_cancellable_work_orders`, {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  patch: (requestBody: any, params: any) => {
    const { id } = params
    return api.$patch(`/background_tasks/${id}/process_task`, {
      background_task: { ...requestBody }
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getErrorReportById: (id: string | number) => {
    return api.$get(`/background_tasks/${id}/download_error_file`)
  },
  getCustomReportHeaders: (body: CustomReportHeaderInterface) => {
    return api.$get('/background_tasks/custom_headers', {
      params: body,
      paramsSerializer: params => {
        return qs.stringify(params)
      }

    })
  },
  getSuccessReportById: (id: string | number) => {
    return api.$get(`/background_tasks/${id}/download_success_file`)
  },
  getNonCancellableWOsWithReasons: ({ taskId }: Params) => {
    return api.$get(`/bulk_cancel_work_orders/${taskId}/non_cancellable_reasons_with_work_orders`)
  }
}
