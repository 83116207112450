import repositories from '@/repositories'
import { baseGetResponse } from '@/utils/api'
import { ERROR, EXPORT, SUCCESS } from '@/constants/icons'
import { useListRow } from './list-rows'
import { MenuAction } from '@/types/interfaces/global/resource-actions'
import { creationAssetsActionData, equipmentActionData, universalAssetsActionData } from '@/constants/actions-by-resource/assets'
import { Asset } from '@/types/interfaces'
import { cloneDeep } from 'lodash'
import { useApp } from './app'
import { BROKER, CLIENT, VENDOR } from '@/constants/permissions'
import { h, ref } from 'vue'
import { useSession } from './session'
import { useUsersCompanies } from './users-companies'
import { useToasts } from './toasts'
import { AssetNote } from '@/types/interfaces/api-v2/asset'
import { useFeatureFlags } from './feature-flags'
import { useDetailsPanel } from './details-panel'
import { useWorkOrders } from './work-orders'
import { INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, WO_RESOURCE_TYPE } from '@/constants/resource-types'
import { useOutboundProposals } from './proposals/outbound'
import { useClients } from './clients'
import { CLIENT_INCLUDE } from '@/constants/include'
import { useOutboundInvoices } from './invoices/outbound'
import { useLocations } from './locations'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { DetailsPanelVisibility } from '@/types/enums'

const { currentUsersCompany } = useUsersCompanies()
const { addToast } = useToasts()

export interface bulkEventData {
  source: string | null
  query: string | null
  assets: Asset[]
}

export interface assetNotes {
  isFromEdit: boolean,
  data: AssetNote | null
}

const photosTableRef = ref<any>(null)

const renderAssetAddNotesModal = ref<boolean>(false)

const renderAssetPhotosModal = ref<boolean>(false)

const renderAssetAttachmentModal = ref<boolean>(false)

const showAssetPhotosModal = ref<boolean>(false)

const assetsTableRef = ref<any>(null)
const assetDetailsRef = ref<any>(null)

export const DEFAULT_ASSET_ATTACHMENT = {
  id: '',
  name: '',
  description: '',
  makeFileAvailableToClient: false,
  makeFileAvailableToVendor: false,
  fileUrl: '',
  status: '',
  attachedFile: []
}

export const DEFAULT_NOTES_ATTACHMENT = {
  attachmentUrl: '',
  id: '',
  name: ''
}

export const DEFAULT_ASSET_NOTE = {
  id: 0,
  equipmentNoteAttachment: {},
  note: '',
  sentEmail: true
}

export const DEFAULT_ASSET_PHOTO = {
  id: '',
  name: '',
  description: '',
  fileUrl: '',
  _file: {} as File,
  createdAt: ''
}

export const DEFAULT_ASSET_DECOMMISSION = {
  id: 0,
  reason: '',
  value: 'No'
}

const assetPhoto = ref(cloneDeep(DEFAULT_ASSET_PHOTO))

const addModal = ref<boolean>(false)
const modelName = ref<string>('manufacturer')
const entityId = ref<string | number | undefined>('')
const renderAssetUploadModal = ref<boolean>(false)
const labelModal = ref<boolean>(false)

export const useAsset = () => {
  const { companyType } = useApp()

  const ACTIVE = 'active'
  const INACTIVE = 'deleted'

  const assetsStatuses = [ACTIVE, INACTIVE]

  let assetsMenuActionsMap = {} as Record<string, MenuAction[]>

  const generateAssetsMenuActions = (assets: Asset, isDetailsPanel = false) => {
    const { generateMenuActions, getActionMenuData } = useListRow()

    let assetsActionDataCopy = cloneDeep(equipmentActionData)

    if (companyType.value === CLIENT) {
      const actionsToRemove = ['+ Work order', '+ Invoice', '+ Proposal']
      assetsActionDataCopy = equipmentActionData.filter(item => !actionsToRemove.includes(item.name))
    } else if (companyType.value === BROKER) {
      const actionsToRemove = ['Service request']
      assetsActionDataCopy = equipmentActionData.filter(item => !actionsToRemove.includes(item.name))
    } else if (companyType.value === VENDOR) {
      const actionsToRemove = ['+ Work order', 'Service request']
      assetsActionDataCopy = equipmentActionData.filter(item => !actionsToRemove.includes(item.name))
    }

    if (!assets?.id) {
      if (creationAssetsActionData) {
        return getActionMenuData('', [], creationAssetsActionData, '', false, null)
      }
      return []
    }

    assetsMenuActionsMap = generateMenuActions(assetsStatuses, assetsMenuActionsMap, universalAssetsActionData, assetsActionDataCopy, '', isDetailsPanel)
  }

  const assetsActionListenersAndFunctions: any = {
    share: (
      assets: any[],
      additionalParams: any
    ) => {
      const localAssetId = assets[0].id
      const localAssetName = assets[0].tagName
      const isFromTable = additionalParams?.isFromTable
      const url = useSession().createSubdomainURL(currentUsersCompany.value.subdomain, `/assets/${localAssetId}`)
      navigator.clipboard.writeText(url).then(function () {
        addToast({
          color: 'success',
          message: !isFromTable ? 'Link copied to clipboard' : '',
          timeout: isFromTable ? 15000 : 5000,
          prependIcon: SUCCESS,
          component: () => isFromTable
            ? h(
              'div', { style: { fontWeight: 500, fontSize: '1rem' } }, [' ', 'Link copied to clipboard for ',
                h('router-link', { props: { to: `/assets/${localAssetId}` } }, [`${localAssetName}`])
              ]
            )
            : h()
        })
      })
    }
  }

  const universalAssetsActions = [
    {
      value: 'export',
      requiresConfirmation: true,
      bulkConfig: {
        type: 'icon',
        icon: EXPORT,
        text: 'Download Asset'
      },
      advancedModal: 'assets/modals/download',
      dynamicContentProps: { keepDetailsOpenOnSave: false }
    },
    {
      value: 'share',
      updateSelectedResource: false,
      actionFn: async (resources: any[], additionalParams: any) => {
        await assetsActionListenersAndFunctions.share(resources, additionalParams)
      }
    },
    {
      value: 'edit',
      requiresConfirmation: false,
      actionFn: (assets: Asset[], { localDetailsPanelComponentProps, router, emit }: any) => {
        if (localDetailsPanelComponentProps) {
          const { openGlobalDetailsPanel } = useDetailsPanel()
          openGlobalDetailsPanel('assets/profile', {
            canDoubleClickToEdit: false,
            resourceType: 'asset',
            visibility: DetailsPanelVisibility.Fullscreen,
            isPublicTab: false,
            closable: true
          }, 'setSelectedResources', assets)
        } else {
          // If we don't have details panel open (as we open details screen in assets/assetId) then need to emit edit action to capture it in .vue in action listener file
          emit('edit')
        }
      }
    }
  ]

  const getAssetsMenuActions = () => {
    return [
      ...universalAssetsActions,
      companyType.value === BROKER
        ? {
            value: 'work-order',
            requiresConfirmation: false,
            bulkConfig: {
              type: 'primary'
            },
            actionFn: (assets: Asset[], localDetailsPanelComponentProps: any) => {
              const { hideVueWorkOrder } = useFeatureFlags()
              if (hideVueWorkOrder) {
                localDetailsPanelComponentProps.router.push('/company/wizard/work_orders/new?header=false&step=details')
              } else {
                useDetailsPanel().openGlobalDetailsPanel('work-orders/details', {
                  outbound: false,
                  canDoubleClickToEdit: false,
                  resourceType: 'workOrder',
                  actions: useWorkOrders().getWorkOrderActions()
                }, 'enterCreateResourceFlow', { prefillData: { company: assets[0]?.company, locations: [assets[0]?.location] } })
              }
            }
          }
        : {},
      {
        value: 'service-request',
        requiresConfirmation: false,
        actionFn: (assets: Asset[], localDetailsPanelComponentProps: any) => {
          localDetailsPanelComponentProps.router.push('/company/service_requests/site_selections')
        }
      },
      {
        value: 'invoice',
        requiresConfirmation: false,
        actionFn: async (assets: Asset[], localDetailsPanelComponentProps: any) => {
          const client = await useClients().getClientById({ id: assets[0]?.company?.id, include: CLIENT_INCLUDE }).then(res => {
            return res.data
          })
          const site = await useLocations().getLocationById({ id: assets[0]?.location?.id, include: '' }).then(res => {
            return res.data
          })
          const allOutboundActions = [
            ...useOutboundInvoices().outboundInvoiceModalRequiredActions,
            ...useOutboundInvoices().basicInvoiceOutboundActions
          ]
          useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
            outbound: true,
            canDoubleClickToEdit: true,
            resourceType: INVOICE_RESOURCE_TYPE,
            actions: allOutboundActions
          }, 'enterCreateResourceFlow', { prefillData: { company: client, locations: [site] } })
        }
      },
      {
        value: 'proposal',
        requiresConfirmation: false,
        actionFn: async (assets: Asset[], localDetailsPanelComponentProps: any) => {
          const client = await useClients().getClientById({ id: assets[0]?.company?.id, include: CLIENT_INCLUDE }).then(res => {
            return res.data
          })
          const site = await useLocations().getLocationById({ id: assets[0]?.location?.id, include: '' }).then(res => {
            return res.data
          })
          const allOutboundActions = () => {
            return [
              ...useOutboundProposals().basicProposalOutboundActions(useApp().companyType.value).map((item: any) => {
                const temp = Object.assign({}, item)
                delete temp.bulkConfig
                return temp
              }),
              ...useOutboundProposals().proposalOutboundModalRequiredActions
            ]
          }
          useDetailsPanel().openGlobalDetailsPanel('uc/resource-details', {
            outbound: true,
            canDoubleClickToEdit: true,
            resourceType: PROPOSAL_RESOURCE_TYPE,
            actions: allOutboundActions()
          }, 'enterCreateResourceFlow', { prefillData: { company: client, location: site } })
        },
        noSuccessEmit: true
      },
      {
        value: 'decommission',
        requiresConfirmation: true,
        bulkConfig: {
          type: companyType.value !== BROKER ? 'primary' : 'menu'
        },
        advancedModal: 'assets/modals/decommission',
        dynamicContentProps: { keepDetailsOpenOnSave: false }
      },
      {
        value: 'recommission',
        requiresConfirmation: true,
        advancedModal: 'assets/modals/recommission',
        dynamicContentProps: { keepDetailsOpenOnSave: false }
      },
      {
        value: 'save',
        updateSelectedResource: true,
        actionFn: (assets: Asset[], { emit }: any) => {
          emit('save')
        }
      },
      {
        value: 'view-public-profile',
        requiresConfirmation: false,
        actionFn: (assets: Asset[], { router }: any) => {
          router.push(`/assets/${assets[0].id}`)
        }
      }
    ]
  }

  const getPhotosMenuActions = [
    {
      value: 'view',
      requiresConfirmation: false,
      actionFn: (photos: any, additionalParams: any) => {
        assetPhoto.value = photos
        showAssetPhotosModal.value = true
      }
    },
    {
      value: 'edit',
      requiresConfirmation: false,
      actionFn: (photos: any, additionalParams: any) => {
        assetPhoto.value = Object.assign(cloneDeep(DEFAULT_ASSET_PHOTO), photos)
        renderAssetPhotosModal.value = true
      }
    },
    {
      value: 'download',
      requiresConfirmation: false,
      bulkConfig: {
        type: 'primary'
      },
      actionFn: async (photos: any, additionalParams: any) => {
        if (photos.length) {
          const response = await bulkDownloadAssetsPhotos({ assetId: additionalParams.selectAllParams.assetId, equipmentPhotoIds: photos.map((photo: any) => photo.id) })
          if (response) {
            const blob = new Blob([response], { type: 'application/zip' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.setAttribute('download', 'equipment_photos.zip') // set download attribute to link
            document.body.appendChild(link)
            link.click() // this will download file.zip
          } else {
            addToast({ color: 'error', timeout: 5000, prependIcon: ERROR, message: 'Something went wrong, please try again later!' })
          }
        } else {
          window.open(photos.fileUrl, '_blank')
        }
      }
    },
    {
      value: 'delete-photo',
      requiresConfirmation: true,
      actionFn: async (photos: any, additionalParams: any) => {
        const response = await deleteAssetPhoto({ assetId: additionalParams.selectAllParams.assetId, photoId: photos.id })
        if (response.data) {
          photosTableRef.value?.removeRows([photos])
          addToast({ color: 'success', timeout: 5000, prependIcon: SUCCESS, message: 'photo removed successfully!' })
        } else {
          addToast({ color: 'error', timeout: 5000, prependIcon: ERROR, message: 'Something went wrong, please try again later!' })
        }
      },
      modal: {
        dynamicContentProps: {
          allowedStatuses: true,
          resourceType: 'photo'
        },
        modalCardProps: {
          headerText: 'Delete Photo',
          headerIcon: 'delete',
          size: 'lg'
        },
        modalContentComponent: null,
        confirmButtonProps: {
          text: 'DELETE PHOTO',
          state: 'error'
        }
      }
    }
  ]

  const getDocumentationsMenuActions = [
    {
      value: 'view',
      requiresConfirmation: false,
      actionFn: (document: any, additionalParams: any) => {
      }
    }
  ]

  const getAssets = async (params: any) => {
    try {
      const res = await repositories.assets.get(params)
      return {
        data: res.equipments,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAssetById = async (params: any) => {
    try {
      const res = await repositories.assets.getById(params)
      return {
        data: res.equipment
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getModelNumbers = async (params: any) => {
    try {
      const res = await repositories.assets.getModelNumbers(params)
      return {
        data: res.equipmentModels,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getFloors = async (params: any) => {
    try {
      const res = await repositories.assets.getFloors(params)
      return {
        data: res.floors,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getPhysicalLocations = async (params: any) => {
    try {
      const res = await repositories.assets.getPhysicalLocations(params)
      return {
        data: res.equipmentLocations,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAreaServed = async (params: any) => {
    try {
      const res = await repositories.assets.getAreaServed(params)
      return {
        data: res.areasServed,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getLabels = async (params: any) => {
    try {
      const res = await repositories.assets.getLabels(params)
      return {
        data: res.companyLabels,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addLabel = async (params: any) => {
    try {
      const res = await repositories.assets.addLabel(params)
      return {
        data: res.companyLabel
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const decommissionAsset = async (params: any) => {
    try {
      const res = await repositories.assets.decommissionAsset(params)
      return {
        data: res.equipments
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const recommissionAsset = async (params: any) => {
    try {
      const res = await repositories.assets.recommissionAsset(params)
      return {
        data: res.equipments
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getTCT = async (params: any) => {
    try {
      const res = await repositories.assets.getTCT(params)
      return {
        data: res.tctMaster,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getManufacturers = async (params: any) => {
    try {
      const res = await repositories.assets.getManufacturers(params)
      return {
        data: res.manufacturers,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addManufacturer = async (params: any) => {
    try {
      const res = await repositories.assets.addManufacturer(params)
      return {
        data: res.manufacturer
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAssetNotes = async (params: any) => {
    try {
      const res = await repositories.assets.getAssetNotes(params)
      return {
        data: res.equipmentNotes,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addAssetNote = async (params: any) => {
    try {
      const res = await repositories.assets.addAssetNote(params)
      return {
        data: res.equipmentNote
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateAssetNote = async (params: any) => {
    try {
      const res = await repositories.assets.updateAssetNote(params)
      return {
        data: res.equipmentNote
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteAssetNote = async (params: any) => {
    try {
      const res = await repositories.assets.deleteAssetNote(params)
      return {
        data: res.message
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAssetActivities = async (params: any) => {
    try {
      const res = await repositories.assets.getAssetActivities(params)
      return {
        data: res.equipmentActivities,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAssetAttachments = async (params: any) => {
    try {
      const res = await repositories.assets.getAssetAttachments(params)
      return {
        data: res.equipmentAttachments,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addAssetAttachment = async (params: any) => {
    try {
      const res = await repositories.assets.addAssetAttachment(params)
      return {
        data: res.equipmentAttachment
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateAssetAttachment = async (params: any) => {
    try {
      const res = await repositories.assets.updateAssetAttachment(params)
      return {
        data: res.equipmentAttachment
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteAssetAttachment = async (params: any) => {
    try {
      const res = await repositories.assets.deleteAssetAttachment(params)
      return {
        data: res.message
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAssetDocumentations = async (params: any) => {
    try {
      const res = await repositories.assets.getAssetDocumentations(params)
      return {
        data: res.documentations,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const downloadAssetDocumentation = async () => {
    try {
      const res = await repositories.assets.downloadAssetDocumentation()
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const getAssetPhotos = async (params: any) => {
    try {
      const res = await repositories.assets.getAssetPhotos(params)
      return {
        data: res.equipmentPhotos,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addAssetPhotos = async (params: any) => {
    try {
      const res = await repositories.assets.addAssetPhotos(params)
      return {
        data: res.equipmentPhoto
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateAssetPhotos = async (params: any) => {
    try {
      const res = await repositories.assets.updateAssetPhotos(params)
      return {
        data: res.equipmentPhoto
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const deleteAssetPhoto = async (params: any) => {
    try {
      const res = await repositories.assets.deleteAssetPhoto(params)
      return {
        data: res.message
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addAsset = async (params: any) => {
    try {
      const res = await repositories.assets.postAsset(params)
      return {
        data: res.equipment
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addModelNumber = async (params: any) => {
    try {
      const res = await repositories.assets.addModelNumber(params)
      return {
        data: res.equipmentModel
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateAsset = async (params: any) => {
    try {
      const res = await repositories.assets.updateAsset(params)
      return {
        data: res.equipment
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const assetsAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.assets.assetsAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const assetsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await assetsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const bulkDownloadAssetsPhotos = async (params: any) => {
    try {
      const res = await repositories.assets.bulkDownloadAssetsPhotos(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const updateAssetProfile = async (params: any) => {
    try {
      const res = await repositories.assets.updateAssetProfile(params)
      return {
        data: res.equipment
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const bulkDownloadAssets = async (params: any) => {
    try {
      const res = await repositories.assets.bulkDownloadAssets(params)
      return res
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }
  const getParentTagByLocation = async (params: any) => {
    try {
      const res = await repositories.assets.getParentTagByLocation(params)
      return {
        data: res.equipments,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      } as baseGetResponse
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addPhysicalLocation = async (params: any) => {
    try {
      const res = await repositories.assets.addPhysicalLocation(params)
      return {
        data: res.equipmentLocation
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const addAreaServed = async (params: any) => {
    try {
      const res = await repositories.assets.addAreaServed(params)
      return {
        data: res.equipmentLocation
      }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  return {
    getAssets,
    assetsActionListenersAndFunctions,
    universalAssetsActions,
    generateAssetsMenuActions,
    getAssetsMenuActions,
    assetsMenuActionsMap,
    assetsTableRef,
    getAssetById,
    getTCT,
    renderAssetUploadModal,
    getPhotosMenuActions,
    getDocumentationsMenuActions,
    renderAssetAddNotesModal,
    renderAssetPhotosModal,
    renderAssetAttachmentModal,
    getManufacturers,
    addManufacturer,
    getAssetNotes,
    addAssetNote,
    updateAssetNote,
    deleteAssetNote,
    getAssetActivities,
    getAssetAttachments,
    addAssetAttachment,
    updateAssetAttachment,
    deleteAssetAttachment,
    getAssetDocumentations,
    downloadAssetDocumentation,
    getAssetPhotos,
    addAssetPhotos,
    updateAssetPhotos,
    deleteAssetPhoto,
    getModelNumbers,
    getFloors,
    getPhysicalLocations,
    getAreaServed,
    getLabels,
    labelModal,
    addLabel,
    decommissionAsset,
    recommissionAsset,
    addAsset,
    addModal,
    modelName,
    entityId,
    addModelNumber,
    updateAsset,
    assetPhoto,
    photosTableRef,
    showAssetPhotosModal,
    assetDetailsRef,
    assetsAutoComplete,
    assetsAutoCompleteFetchFn,
    bulkDownloadAssetsPhotos,
    updateAssetProfile,
    getParentTagByLocation,
    bulkDownloadAssets,
    addPhysicalLocation,
    addAreaServed
  }
}
