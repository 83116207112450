import {
  TAG_NAME, MANUFACTURER, TAG_ID, TYPE, FLOOR, PHYSICAL_LOCATION, AREA_SERVED, CATEGORY, TRADE, CONDITION, LABELS, PARENT_TAG_ID, INSTALL_DATE, MODEL_NUMBER, SERIAL_NUMBER, MANUFACTURER_DATE, MATERIAL_WARRANTY_END, LABOR_WARRANTY_END, DECOMMISSIONED_BY_REASON, DECOMMISSIONED_BY_USER, DECOMMISSIONED_BY_ORG, INSTALL_BY_USER, INSTALL_BY_ORG, EQUIPMENT_ID, RECORD_CREATION, TAX_CAPITALIZATION_STATUS, CUSTOMER_EQUIPMENT_ID, LAST_ACTIVITY_DATE, FIRST_TAGGED_DATE, PARENT_TAG_NAME, TAGGED_BY_USER, TAGGED_BY_ORG, DECOMMISSIONED,
  IS_COMPONENT_ASSET
} from '../resources/asset'
import { CLIENT_NAME } from '../resources/client'
import { SITE_NAME, SITE_ID } from '../resources/site'
import { useAsset } from '@/use/asset'

const { assetsAutoCompleteFetchFn } = useAsset()

export const allAssetsFilters = () => {
  return {
    filters: [
      TAG_NAME({ pinned: true, isPrimary: true }),
      TYPE({ pinned: true, isPrimary: true }),
      CLIENT_NAME({ pinned: false, isPrimary: false }, { field: 'client_name', tab: 'active' }, assetsAutoCompleteFetchFn),
      SITE_NAME({ pinned: false, isPrimary: false }, { field: 'site_name', invoiceables: false }, assetsAutoCompleteFetchFn),
      SITE_ID({ pinned: false, isPrimary: false }, { field: 'site_id', tab: 'active' }, assetsAutoCompleteFetchFn),
      MANUFACTURER({ pinned: true, isPrimary: true }),
      TAG_ID({ pinned: false, isPrimary: true }),
      FLOOR({ pinned: false, isPrimary: false }),
      PHYSICAL_LOCATION({ pinned: false, isPrimary: false }),
      AREA_SERVED({ pinned: false, isPrimary: false }),
      CATEGORY({ pinned: false, isPrimary: false }),
      TRADE({ pinned: false, isPrimary: false }),
      CONDITION({ pinned: false, isPrimary: false }),
      LABELS({ pinned: false, isPrimary: false }),
      PARENT_TAG_ID({ pinned: false, isPrimary: false }),
      INSTALL_DATE({ pinned: false, isPrimary: false }),
      MODEL_NUMBER({ pinned: true, isPrimary: false }),
      SERIAL_NUMBER({ pinned: false, isPrimary: false }),
      MANUFACTURER_DATE({ pinned: false, isPrimary: false }),
      MATERIAL_WARRANTY_END({ pinned: false, isPrimary: false }),
      LABOR_WARRANTY_END({ pinned: false, isPrimary: false }),
      DECOMMISSIONED_BY_REASON({ pinned: false, isPrimary: false }),
      DECOMMISSIONED_BY_USER({ pinned: false, isPrimary: false }),
      DECOMMISSIONED_BY_ORG({ pinned: false, isPrimary: false }),
      INSTALL_BY_USER({ pinned: false, isPrimary: false }),
      INSTALL_BY_ORG({ pinned: false, isPrimary: false }),
      EQUIPMENT_ID({ pinned: false, isPrimary: false }),
      // DESCRIPTION({ pinned: false, isPrimary: false }),
      RECORD_CREATION({ pinned: false, isPrimary: false }),
      TAX_CAPITALIZATION_STATUS({ pinned: false, isPrimary: false }),
      CUSTOMER_EQUIPMENT_ID({ pinned: false, isPrimary: false }),
      LAST_ACTIVITY_DATE({ pinned: false, isPrimary: false }),
      FIRST_TAGGED_DATE({ pinned: false, isPrimary: false }),
      PARENT_TAG_NAME({ pinned: false, isPrimary: false }),
      TAGGED_BY_USER({ pinned: false, isPrimary: false }),
      TAGGED_BY_ORG({ pinned: false, isPrimary: false }),
      // SITE_ADDRESS({ pinned: false, isPrimary: false }),
      DECOMMISSIONED({ pinned: false, isPrimary: false }),
      IS_COMPONENT_ASSET({ pinned: false, isPrimary: false })
    ],
    sortBy: {
      value: 'id',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Creation Date',
          value: 'created_at'
        },
        {
          label: 'Asset Id',
          value: 'id'
        },
        {
          label: 'Tag Name',
          value: 'tag_name'
        },
        {
          label: 'Type',
          value: 'equipment_type_label'
        },
        {
          label: 'Client',
          value: 'location_company_name'
        },
        {
          label: 'Site',
          value: 'location_name'
        },
        {
          label: 'Manufacturer',
          value: 'manufacturer_name'
        },
        {
          label: 'Model',
          value: 'model_description'
        },
        {
          label: 'Serial',
          value: 'serial_number'
        },
        {
          label: 'Tag Id',
          value: 'qr_rfid_tag'
        }
      ]
    },
    fieldMapping: {
      'asset-tag-name': {
        name: 'tag_name',
        field: 'value'
      },
      'asset-type': {
        name: 'equipment_type_label',
        field: 'value'
      },
      'asset-manufacturer': {
        name: 'manufacturer_name',
        field: 'value'
      },
      'model-number': {
        name: 'equipment_model_model_number',
        field: 'value'
      },
      'tag-id': {
        name: 'qr_rfid_tag',
        field: 'value'
      },
      'asset-floor': {
        name: 'floor_name',
        field: 'value'
      },
      'physical-location': {
        name: 'physical_location_name',
        field: 'value'
      },
      'area-served': {
        name: 'area_served_name',
        field: 'value'
      },
      'asset-category': {
        name: 'equipment_category_label',
        field: 'value'
      },
      'asset-trade': {
        name: 'equipment_trade_label',
        field: 'value'
      },
      'asset-condition': {
        name: 'current_condition',
        field: 'value'
      },
      'asset-labels': {
        name: 'company_labels_name',
        field: 'value'
      },
      'parent-tag-id': {
        name: 'parent_equipment_qr_rfid_tag',
        field: 'value'
      },
      'install-date': {
        name: 'install_date',
        field: 'value'
      },
      'serial-number': {
        name: 'serial_number',
        field: 'value'
      },
      'manufacturer-date': {
        name: 'manufacture_date',
        field: 'value'
      },
      'material-warranty-end': {
        name: 'warranty_material_warranty_expiry_date',
        field: 'value'
      },
      'labor-warranty-end': {
        name: 'warranty_labor_warranty_expiry_date',
        field: 'value'
      },
      'decommissioned-reason': {
        name: 'decommission_reason',
        field: 'value'
      },
      'decommissioned-user': {
        name: 'decommission_user_full_name',
        field: 'value'
      },
      'decommissioned-org': {
        name: 'decommission_company_name',
        field: 'value'
      },
      'installed-by-user': {
        name: 'installation_user_full_name',
        field: 'value'
      },
      'installed-by-org': {
        name: 'installation_company_name',
        field: 'value'
      },
      'equipment-id': {
        name: 'id',
        field: 'value'
      },
      'client-name': {
        name: 'location_company_name',
        field: 'value'
      },
      'site-name': {
        name: 'location_name',
        field: 'value'
      },
      'record-creation': {
        name: 'created_at',
        field: 'value'
      },
      'tax-capitalization-status': {
        name: 'tax_captalization_status',
        field: 'value'
      },
      'customer-equipment-id': {
        name: 'customer_equipment_code',
        field: 'value'
      },
      'last-activity-date': {
        name: 'last_activity_date',
        field: 'value'
      },
      'first-tagged-date': {
        name: 'tag_first_tag_date',
        value: 'value'
      },
      'parent-tag-name': {
        name: 'parent_equipment_tag_name',
        field: 'value'
      },
      'tagged-by-user': {
        name: 'tag_user_full_name',
        field: 'value'
      },
      'tagged-by-org': {
        name: 'tag_company_name',
        field: 'value'
      },
      'asset-decommissioned': {
        name: 'status',
        field: 'value'
      },
      'site-id': {
        name: 'location_id',
        field: 'value'
      },
      'is-component': {
        name: 'is_component',
        field: 'value'
      }
    }
  }
}
