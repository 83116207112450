
import {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
} from '@/constants/filters/resources/purchaseOrder'
import {
  CLIENT_NAME
} from '@/constants/filters/resources/client'
import { useSession } from '@/use/session'
import { BROKER, CLIENT } from '@/constants/permissions'
import { WORK_ORDER_NUMBER } from '../resources/workOrder'
import { useInboundPurchaseOrders } from '@/use/purchase-orders/inbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { CompanyRoleTypes } from '@/types/enums'

export const recievedPurchaseOrderFilters = () => {
  const { session } = useSession()
  const { inboundPurchaseOrdersAutoComplete } = useInboundPurchaseOrders()

  const inboundPurchaseOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await inboundPurchaseOrdersAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const availableWorkOrderFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, inboundPurchaseOrdersAutoCompleteFetchFn))
    }
    return baseFilters
  }

  return {
    'purchase-order': {
      filters: [
        PO_NUMBER(false, { pinned: true, isPrimary: true }, { field: 'po_number' }, inboundPurchaseOrdersAutoCompleteFetchFn),
        PO_CUSTOM_NUMBER(false, { pinned: false, isPrimary: false }, { field: 'custom_po_number' }, inboundPurchaseOrdersAutoCompleteFetchFn),
        PO_STATUS(CompanyRoleTypes.VENDOR, { pinned: true, isPrimary: true }),
        PO_DATE_OF_ISSUE(false, { pinned: false, isPrimary: false })
      ],
      sortBy: {
        value: 'po_number',
        direction: {
          text: 'Ascending',
          value: 'asc'
        },
        items: [
          {
            label: 'PO #',
            value: 'po_number'
          },
          {
            label: 'Status',
            value: 'status'
          },
          {
            label: 'Date of Issue',
            value: 'date_of_issue'
          },
          {
            label: 'Amount',
            value: 'amount_cents'
          }
        ]
      },
      fieldMapping: {
        'purchase-order-number': {
          name: 'po_number',
          field: 'value'
        },
        'purchase-order-custom-number': {
          name: 'custom_po_number',
          field: 'value'
        },
        'purchase-order-status': {
          name: 'status',
          field: 'value'
        },
        'purchase-order-date-of-issue': {
          name: 'date_of_issue',
          field: 'value'
        }
      }
    },
    'work-order': {
      filters: [
        ...availableWorkOrderFilters()
      ],
      fieldMapping: {
        'work-order-number': {
          name: 'work_order_id',
          field: 'value'
        }
      }
    },
    clients: {
      filters: [
        CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, inboundPurchaseOrdersAutoCompleteFetchFn)
      ],
      fieldMapping: {
        'client-name': {
          name: 'company_name',
          field: 'value'
        }
      }
    }
  }
}
