import {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE
} from '@/constants/filters/resources/invoice'

import {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE,
  WEATHER_EVENT_END_DATE
} from '@/constants/filters/resources/weatherEvent'

import {
  CLIENT_NAME,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS
} from '@/constants/filters/resources/client'

import {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION
} from '@/constants/filters/resources/site'

import { VENDOR_NAME } from '../resources/vendor'

import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import {
  WORK_ORDER_NUMBER,
  WORK_ORDER_SERVICE,
  WORK_ORDER_TRADE,
  WORK_ORDER_CUSTOM_STATUS,
  WORK_ORDER_STATUS,
  WORK_ORDER_SERVICE_CHANNEL_CONNECTION,
  WORK_ORDER_CORRIGO_CONNECTION,
  WORK_ORDER_START_DATE,
  WORK_ORDER_EXPIRATION_DATE,
  WORK_ORDER_SERVICE_CHECK_IN_TIME,
  WORK_ORDER_SERVICE_CHECK_OUT_TIME,
  WORK_ORDER_EXTERNAL_REGION
} from '../resources/workOrder'

import {
  TRIP_CREATION_DATE,
  TRIP_EXPIRATION_DATE,
  TRIP_SERVICE_CHECK_IN_TIME,
  TRIP_SERVICE_CHECK_OUT_TIME
} from '../resources/trip'

import { computed } from 'vue'
import { useSession } from '@/use/session'
import { BROKER, CLIENT } from '@/constants/permissions'
import { useOutboundInvoices } from '@/use/invoices/outbound'
import { ACTION_STATUS_FILTER } from '../resources/action-filter'
import { FilterResources } from '@/types/enums/filter-types'
import { startCase } from 'lodash'
import { CompanyRoleTypes } from '@/types/enums'
const { invoicesAutoComplete } = useOutboundInvoices()

export const allReceivableInvoiceFilters = () => {
  const { session } = useSession()

  const invoiceAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    params.finderParams = { includeGrouped: true, objectScope: 'both' }
    const { data, nextPage } = await invoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: params.field === 'action_status' ? startCase(String(item.text)) : item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const availableSiteFilters = () => {
    const baseFilters: any = [
    // SITE_COUNTRY({ pinned: false, isPrimary: false }),
    // SITE_CITY({ pinned: false, isPrimary: false }),
    // SITE_ZIP({ pinned: false, isPrimary: false }),
    // SITE_ADDRESS({ pinned: false, isPrimary: false })
    // SITE_REGION({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER || session.currentCompanyType === CLIENT) {
      baseFilters.push(SITE_NAME({ pinned: true, isPrimary: true }, { field: 'site', invoiceables: false }, invoiceAutoCompleteFetchFn))
    }
    return baseFilters
  }

  const availableWeatherEventFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WEATHER_EVENT({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_START_DATE({ pinned: false, isPrimary: false }),
        WEATHER_EVENT_END_DATE({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id' }, invoiceAutoCompleteFetchFn),
      WORK_ORDER_SERVICE_CHANNEL_CONNECTION({ pinned: true, isPrimary: true }, { field: 'service_channel_connection_id' }, invoiceAutoCompleteFetchFn),
      WORK_ORDER_CORRIGO_CONNECTION({ pinned: true, isPrimary: true }, { field: 'corrigo_connection_id' }, invoiceAutoCompleteFetchFn),
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }, { field: 'trade_service' }, invoiceAutoCompleteFetchFn),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false }, { field: 'trade' }, invoiceAutoCompleteFetchFn),
        WORK_ORDER_CUSTOM_STATUS({ pinned: false, isPrimary: false }),
        WORK_ORDER_STATUS({ pinned: false, isPrimary: false }),
        WORK_ORDER_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
        WORK_ORDER_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false }),
        TRIP_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
        TRIP_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false }),
        WORK_ORDER_EXTERNAL_REGION({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableActionFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType !== CLIENT) {
      baseFilters.push(
        ACTION_STATUS_FILTER({ pinned: false, isPrimary: false }, invoiceAutoCompleteFetchFn, { field: 'action_status' }, FilterResources.INVOICE)
      )
    }
    return baseFilters
  }

  const availableTripFilters = () => {
    const baseFilters: any = [
      TRIP_CREATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false })
    ]
    return baseFilters
  }

  const availableVendorFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType !== CLIENT) {
      baseFilters.push(
        VENDOR_NAME(
          { pinned: true, isPrimary: true, ransackableScope: true },
          { field: 'vendor' },
          invoiceAutoCompleteFetchFn
        )
      )
    }
    return baseFilters
  }

  return {
    filters: [
      INVOICE_NUMBER(true, { pinned: true, isPrimary: true }),
      INVOICE_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }),
      INVOICE_STATUS(CompanyRoleTypes.VENDOR, { pinned: true, isPrimary: true }),
      INVOICE_DATE_OF_ISSUE(false, { pinned: false, isPrimary: false }),
      ...availableWeatherEventFilters(),
      INVOICE_DUE_DATE(false, { pinned: true, isPrimary: true }),
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, invoiceAutoCompleteFetchFn),
      ...availableSiteFilters(),
      ...availableWorkOrderFilters(),
      ...availableActionFilters(),
      ...availableTripFilters(),
      ...availableVendorFilters()
    ],
    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Due Date',
          value: 'due_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Invoice #',
          value: 'short_invoice_number'
        },
        {
          label: 'Amount Due',
          value: 'payable_balance_cents'
        }
      ]
    },
    fieldMapping: {
      'invoice-number': {
        name: 'short_invoice_number',
        field: 'value'
      },
      'invoice-custom-number': {
        name: 'custom_invoice_number',
        field: 'value'
      },
      'invoice-work-order-number': {
        name: 'work_orders_id',
        field: 'value'
      },
      'invoice-status': {
        name: 'calculated_status_code',
        field: 'value'
      },
      'invoice-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'invoice-due-date': {
        name: 'due_date',
        field: 'value'
      },
      'weather-event': {
        name: 'event_id',
        field: 'value'
      },
      'weather-event-start-date': {
        name: 'event_start_date',
        field: 'value'
      },
      'weather-event-end-date': {
        name: 'invoice_resources_resource_of_WeatherWorks::StormDetail_type_storm_end',
        field: 'value'
      },
      'client-name': {
        name: 'company_name',
        field: 'value'
      },
      'client-country': {
        name: 'company_address_country',
        field: 'value'
      },
      'client-state': {
        name: 'company_address_state',
        field: 'value'
      },
      'client-city': {
        name: 'company_address_city',
        field: 'value'
      },
      'client-zip': {
        name: 'company_address_zip_code',
        field: 'value'
      },
      'client-address': {
        name: 'company_address_full_address',
        field: 'value'
      },
      'site-name': {
        name: 'invoice_locations_location_name',
        field: 'value'
      },
      'site-country': {
        name: 'locations_address_country',
        field: 'value'
      },
      'site-city': {
        name: 'locations_address_city',
        field: 'value'
      },
      'site-zip': {
        name: 'locations_address_zip_code',
        field: 'value'
      },
      'site-address': {
        name: 'locations_address_full_address',
        field: 'value'
      },
      'site-region': {
        name: 'locations_cc_territory_name',
        field: 'text'
      },
      'work-order-number': {
        name: 'filtered_wos_id',
        field: 'value'
      },
      'work-order-service': {
        name: 'work_order_work_order_trade_services_trade_service_name',
        field: 'value'
      },
      'work-order-trade': {
        name: [
          'work_order_work_order_trades_trade_name',
          'invoice_trips_trip_work_order_work_order_trades_trade_name'
        ],
        field: 'value'
      },
      'work-order-event-end-date': {
        name: 'invoice_resources_resource_of_WeatherWorks::StormDetail_type_storm_end_date',
        field: 'value'
      },
      'work-order-status': {
        name: 'work_order_work_order_status_name',
        field: 'text'
      },
      'work-order-custom-status': {
        name: 'work_order_work_order_status_name',
        field: 'value'
      },
      'corrigo-connection': {
        name: 'work_order_corrigo_connection_external_id',
        field: 'value'
      },
      'service-channel-connection': {
        name: 'work_order_trips_service_channel_connection_external_id',
        field: 'value'
      },
      'action-status': {
        name: 'cc_required_actions_action',
        field: 'value'
      },
      'work-order-start-date': {
        name: 'work_order_created_at',
        field: 'value'
      },
      'work-order-expiration-date': {
        name: 'work_order_expiration_date',
        field: 'value'
      },
      'trip-creation-date': {
        name: 'trips_start_date',
        field: 'value'
      },
      'trip-expiration-date': {
        name: 'trips_expiration_date',
        field: 'value'
      },
      'vendor-name': {
        name: 'filter_by_contractors',
        field: 'value'
      },
      'work-order-service-check-in': {
        name: 'work_order_services_effective_check_in_time',
        field: 'value'
      },
      'work-order-service-check-out': {
        name: 'work_order_services_effective_check_out_time',
        field: 'value'
      },
      'trip-service-check-in': {
        name: 'work_order_trips_services_effective_check_in_time',
        field: 'value'
      },
      'trip-service-check-out': {
        name: 'work_order_trips_services_effective_check_out_time',
        field: 'value'
      },
      'external-region': {
        name: 'work_order_locations_agreements_external_region',
        field: 'value'
      }
    }
  }
}
