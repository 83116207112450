
import {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
} from '@/constants/filters/resources/purchaseOrder'
import {
  WORK_ORDER_NUMBER
} from '@/constants/filters/resources/workOrder'
import {
  VENDOR_NAME
} from '@/constants/filters/resources/vendor'
import { useOutboundPurchaseOrders } from '@/use/purchase-orders/outbound'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { CompanyRoleTypes } from '@/types/enums'

export const sentPurchaseOrderFilters = () => {
  const { purchaseOrdersAutoComplete } = useOutboundPurchaseOrders()

  const purchaseOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await purchaseOrdersAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  return {
    'purchase-order': {
      filters: [
        PO_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'po_number' }, purchaseOrdersAutoCompleteFetchFn),
        PO_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'custom_po_number' }, purchaseOrdersAutoCompleteFetchFn),
        PO_STATUS(CompanyRoleTypes.CLIENT, { pinned: true, isPrimary: true }),
        PO_DATE_OF_ISSUE(true, { pinned: true, isPrimary: true })
      ],
      sortBy: {
        value: 'po_number',
        direction: {
          text: 'Ascending',
          value: 'asc'
        },
        items: [
          {
            label: 'PO #',
            value: 'po_number'
          },
          {
            label: 'Status',
            value: 'status'
          },
          {
            label: 'Date of Issue',
            value: 'date_of_issue'
          },
          {
            label: 'Amount',
            value: 'amount_cents'
          }
        ]
      },
      fieldMapping: {
        'purchase-order-number': {
          name: 'po_number',
          field: 'value'
        },
        'purchase-order-custom-number': {
          name: 'custom_po_number',
          field: 'value'
        },
        'purchase-order-status': {
          name: 'status',
          field: 'value'
        },
        'purchase-order-date-of-issue': {
          name: 'date_of_issue',
          field: 'value'
        }
      }
    },
    'work-order': {
      filters: [
        WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, purchaseOrdersAutoCompleteFetchFn)
      ],
      fieldMapping: {
        'work-order-number': {
          name: 'work_order_id',
          field: 'value'
        }
      }
    },
    vendor: {
      filters: [
        VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'vendor' }, purchaseOrdersAutoCompleteFetchFn)
      ],
      fieldMapping: {
        'vendor-name': {
          name: 'vendor_name',
          field: 'value'
        }
      }
    }
  }
}
