import { api, apis } from '@/api'
import qs from 'qs'

interface Params {
  include: string
  q: any
  page: number
  perPage: number
}

export default {
  getCompanySettings: () => api.$get('/company_settings'),

  getPaymentTerms: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage } = parameters
    return apis.$get('/payment_terms', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getCompanySetValues: (companyId: number) => {
    return api.$get(`companies/${companyId}/get_company_set_values`)
  },

  getVendorSetting: (params: any) => {
    const parameters: Params = params
    const { include, q, page, perPage } = parameters
    return apis.$get('settings/vendor', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  updateVendorSetting: (vendorSettingObject: any, params: any) => {
    return api.$patch('settings/vendor', vendorSettingObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  }
}
