import { generateInvoicesFilters } from '@/constants/filters/tabs/generate-invoices'
import { attentionInvoiceFilters } from '@/constants/filters/tabs/attention-invoices'
import { generateVendorInvoiceFilters } from '@/constants/filters/tabs/generate-vendor-invoices'
import { pendingApprovalFilters } from '@/constants/filters/tabs/pending-approval'
import { payInvoiceFilters } from '@/constants/filters/tabs/pay'
import { sentProposalFilters } from '@/constants/filters/tabs/sent-proposals'
import { recievedProposalFilters } from '@/constants/filters/tabs/received-proposals'
import { madePaymentFilters } from '@/constants/filters/tabs/made-payments'
import { receivedPaymentFilters } from '@/constants/filters/tabs/received-payments'
import { recievedPurchaseOrderFilters } from '@/constants/filters/tabs/received-po'
import { sentPurchaseOrderFilters } from '@/constants/filters/tabs/sent-po'
import { allInvoiceFilters } from '@/constants/filters/tabs/all-invoices'
import { analyticsPayableFilters } from '@/constants/filters/tabs/analytics-payable'
import { vendorApplicationFilters } from '@/constants/filters/tabs/vendor-applications'
import { brokerTemplateFilters } from '@/constants/filters/tabs/broker-templates'
import { reviewApplicationFilters } from '@/constants/filters/tabs/review-applications'
import { analyticsReceivableFilters } from '@/constants/filters/tabs/analytics-receivable'
import { paidInvoiceFilters } from '@/constants/filters/tabs/paid-invoices'
import { inboundToBeSentProposalFilters } from '@/constants/filters/tabs/inbound-to-be-sent-proposals'
import { outboundToBeSentProposalFilters } from '@/constants/filters/tabs/outbound-to-be-sent-proposals'
import { inboundActionRequiredProposalFilters } from '@/constants/filters/tabs/inbound-action-required-proposals'
import { outboundActionRequiredProposalFilters } from '@/constants/filters/tabs/outbound-action-required-proposals'
import { inboundConvertProposalFilters } from '@/constants/filters/tabs/inbound-convert-proposals'
import { outboundCovertProposalFilters } from '@/constants/filters/tabs/outbound-convert-proposals'
import { operationFailureFilters } from '@/constants/filters/tabs/operations-failure'
import { mustScheduleFilters } from '@/constants/filters/tabs/must-schedule'
import { verificationRequiredFilters } from '@/constants/filters/tabs/verification-required'
import { inProgressFilters } from '@/constants/filters/tabs/in-progress'
import { allTripsFilters } from '@/constants/filters/tabs/all-trips'
import { draftTripsFilters } from '@/constants/filters/tabs/draft-trips'
import { workOrderFilters } from '@/constants/filters/tabs/work-orders'

// import vendorsActive from '@/constants/filters/tabs/vendors-active'
// import vendorsBlocked from '@/constants/filters/tabs/vendors-blocked'
// import vendorsPending from '@/constants/filters/tabs/vendors-pending'

// not-applied filters
import { activeClientsFilters } from '@/constants/filters/tabs/not-applied/clients-active'
import { blockedClientsFilters } from '@/constants/filters/tabs/not-applied/clients-blocked'
import { pendingClientsFilters } from '@/constants/filters/tabs/not-applied/clients-pending'
import { peopleCrewFilters } from '@/constants/filters/tabs/not-applied/peoples-crews'
import { peopleRolesFilter } from '@/constants/filters/tabs/not-applied/peoples-roles'
import { peopleEmployeesFilter } from '@/constants/filters/tabs/not-applied/peoples-employees'

import { allOutboundPurchaseOrderFilters } from './tabs/outbound-all-po'
import { allInboundPurchaseOrderFilters } from './tabs/inbound-all-po'
import { allReceivableInvoiceFilters } from './tabs/receiveable-all-invoices'
import { allPayableInvoiceFilters } from './tabs/payable-all-invoices'

import { kebabCase } from 'lodash'

import {
  exploreInboundInvoicesTab,
  exploreOutboundInvoicesTab,
  explorePaymentsTab,
  exploreBillPaymentsTab,
  exploreSentProposalsTab,
  exploreReceivedProposalsTab,
  exploreInboundPurchaseOrdersTab,
  exploreOutboundPurchaseOrdersTab,
  GENERATE_INVOICES_TAB,
  ALL_RECEIVABLE_INVOICES_TAB,
  ATTENTION_INVOICES_TAB,
  ANALYTICS_RECEIVABLE_TAB,
  ALL_PAYABLE_INVOICES_TAB,
  PENDING_APPROVAL_TAB,
  BULK_PAY_TAB,
  ANALYTICS_PAYABLE_TAB,
  VENDOR_APPLICATIONS_TAB,
  BROKER_TEMPLATES_TAB,
  REVIEW_APPLICATIONS_TAB,
  TEAM_MEMBERS_TAB,
  ROLES_TAB,
  CREW_TAB,
  ACTIVE_CLIENTS_TAB,
  PENDING_CLIENTS_TAB,
  BLOCKED_CLIENTS_TAB,
  ALL_PROPOSALS_SENT_TAB,
  ALL_PROPOSALS_RECEIVED_TAB,
  TO_BE_SENT_PROPOSALS_TAB,
  PROPOSALS_SENT_TAB,
  PROPOSALS_RECEIVED_TAB,
  PROPOSAL_SENT_ACTION,
  PROPOSAL_RECEIVED_ACTION,
  PROPOSAL_SENT_CONVERT_WORK_ORDER,
  PROPOSAL_RECEIVED_CONVERT_WORK_ORDER,
  PURCHASE_ORDER_DRAFT_SENT_TAB,
  ALL_SENT_PURCHASE_ORDERS_TAB,
  ALL_RECEIVED_PURCHASE_ORDERS_TAB,
  PURCHASE_ORDER_REQUIRES_ACTION_RECEIVED_TAB,
  PURCHASE_ORDER_REQUIRES_ACTION_SENT_TAB,
  ALL_TRIPS_TAB,
  IN_PROGRESS_TAB,
  VERIFICATION_REQUIRED_TAB,
  MUST_SCHEDULE_TAB,
  OPERATIONS_FAILURE_TAB,
  DRAFT_TRIPS_TAB,
  GENERATE_VENDOR_INVOICES_TAB,
  PAYMENTS_TAB,
  MADE_PAYMENTS_TAB
} from '@/constants/nested-tabs'
import { ORDERS_TAB, VENDORS_TAB, ADVANCE_VENDOR_PUBLIC_SEARCH, ADVANCE_VENDOR_PRIVATE_SEARCH, ASSETS_TAB } from '../top-level-tabs'
import { outboundProposalFilters } from './tabs/outbound-all-proposals'
import { allInboundProposalsFilters } from './tabs/inbound-all-proposals'
import { vendorsFilters } from './tabs/not-applied/vendors'
import { VendorFilterTabs } from './resources/vendor'
import { allAssetsFilters } from './tabs/assets'

export const allFilters = (tabId: number | string) => {
  if (!tabId || tabId < 0) return {}
  const filters = {
  // WORK ORDERS
    [ORDERS_TAB.id]: { function: workOrderFilters },
    // RECEIVABLES
    [GENERATE_INVOICES_TAB.id]: { function: generateInvoicesFilters },
    [ALL_RECEIVABLE_INVOICES_TAB.id]: { function: allReceivableInvoiceFilters },
    [ATTENTION_INVOICES_TAB.id]: { function: attentionInvoiceFilters },
    [ANALYTICS_RECEIVABLE_TAB.id]: { function: analyticsReceivableFilters },
    // PAYABLES
    [GENERATE_VENDOR_INVOICES_TAB.id]: { function: generateVendorInvoiceFilters },
    [ALL_PAYABLE_INVOICES_TAB.id]: { function: allPayableInvoiceFilters },
    [PENDING_APPROVAL_TAB.id]: { function: pendingApprovalFilters },
    [BULK_PAY_TAB.id]: { function: payInvoiceFilters },
    [ANALYTICS_PAYABLE_TAB.id]: { function: analyticsPayableFilters },
    // RECRUIT
    [VENDOR_APPLICATIONS_TAB.id]: { function: vendorApplicationFilters },
    [BROKER_TEMPLATES_TAB.id]: { function: brokerTemplateFilters },
    [REVIEW_APPLICATIONS_TAB.id]: { function: reviewApplicationFilters },
    // EXPLORE OUT OF SCOPE FOR NOW
    [kebabCase(exploreSentProposalsTab)]: { function: sentProposalFilters },
    [kebabCase(exploreReceivedProposalsTab)]: { function: recievedProposalFilters },
    [kebabCase(explorePaymentsTab)]: { function: receivedPaymentFilters },
    [kebabCase(exploreBillPaymentsTab)]: { function: madePaymentFilters },
    [kebabCase(exploreInboundPurchaseOrdersTab)]: { function: recievedPurchaseOrderFilters },
    [kebabCase(exploreOutboundPurchaseOrdersTab)]: { function: sentPurchaseOrderFilters },
    // EMPLOYEES
    [TEAM_MEMBERS_TAB.id]: { function: peopleEmployeesFilter },
    [ROLES_TAB.id]: { function: peopleRolesFilter },
    [CREW_TAB.id]: { function: peopleCrewFilters },
    // VENDORS
    [VENDORS_TAB.id]: { function: vendorsFilters, params: VendorFilterTabs.VENDOR },
    [ADVANCE_VENDOR_PUBLIC_SEARCH.id]: { function: vendorsFilters, params: VendorFilterTabs.ADVANCED_PUBLIC },
    [ADVANCE_VENDOR_PRIVATE_SEARCH.id]: { function: vendorsFilters, params: VendorFilterTabs.ADVANCED_PRIVATE },
    // CLIENTS
    [ACTIVE_CLIENTS_TAB.id]: { function: activeClientsFilters },
    [PENDING_CLIENTS_TAB.id]: { function: pendingClientsFilters },
    [BLOCKED_CLIENTS_TAB.id]: { function: blockedClientsFilters },
    // PROPOSALS
    [ALL_PROPOSALS_SENT_TAB.id]: { function: outboundProposalFilters },
    [ALL_PROPOSALS_RECEIVED_TAB.id]: { function: allInboundProposalsFilters },
    [PROPOSALS_SENT_TAB.id]: { function: outboundToBeSentProposalFilters },
    [PROPOSALS_RECEIVED_TAB.id]: { function: inboundToBeSentProposalFilters },
    [PROPOSAL_SENT_ACTION.id]: { function: outboundActionRequiredProposalFilters },
    [PROPOSAL_RECEIVED_ACTION.id]: { function: inboundActionRequiredProposalFilters },
    [PROPOSAL_SENT_CONVERT_WORK_ORDER.id]: { function: outboundCovertProposalFilters },
    [PROPOSAL_RECEIVED_CONVERT_WORK_ORDER.id]: { function: inboundConvertProposalFilters },
    // PURCHASE ORDERS
    [ALL_SENT_PURCHASE_ORDERS_TAB.id]: { function: allOutboundPurchaseOrderFilters },
    [ALL_RECEIVED_PURCHASE_ORDERS_TAB.id]: { function: allInboundPurchaseOrderFilters },
    // TRIPS
    [OPERATIONS_FAILURE_TAB.id]: { function: operationFailureFilters },
    [MUST_SCHEDULE_TAB.id]: { function: mustScheduleFilters },
    [VERIFICATION_REQUIRED_TAB.id]: { function: verificationRequiredFilters },
    [IN_PROGRESS_TAB.id]: { function: inProgressFilters },
    [ALL_TRIPS_TAB.id]: { function: allTripsFilters },
    [DRAFT_TRIPS_TAB.id]: { function: draftTripsFilters },
    // PAYMENTS
    [PAYMENTS_TAB.id]: { function: receivedPaymentFilters },
    [MADE_PAYMENTS_TAB.id]: { function: madePaymentFilters },
    // ASSETS
    [ASSETS_TAB.id]: { function: allAssetsFilters }
  } as Record<string, {function: (params?: any) => any, params?: string}>

  if (filters[tabId].params) {
    return { [tabId]: filters[tabId].function(filters[tabId].params) }
  } else {
    return { [tabId]: filters[tabId].function() }
  }
}
