import repositories from '@/repositories'
import { reactive } from 'vue'
type Agreements = {
  loading: boolean,
  list: Array<any>,
  latest:any,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
}
const Agreements = reactive<Agreements>({
  loading: false,
  list: [],
  latest: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

export const useAgreements = () => {
  const getExternalRegions = async () => {
    try {
      const res: any = await repositories.agreements.getExternalRegions()
      return {
        data: res.externalRegions,
        totalCount: res?.externalRegions?.length || 0,
        totalPages: 1
      }
    } catch (err) {
      console.log(err)
      return { data: [] }
    }
  }
  return {
    Agreements,
    getExternalRegions
  }
}
