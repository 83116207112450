import { api, apis } from '@/api'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { InvoiceParams } from '@/utils/api'

interface getInboundInvoicesParams {
  perPage?: number
  page?: number
  q?: any
  scope?: string,
  objectScope?:string,
  include?: string,
  includeEngagementStatus?: boolean
  workOrderId?: number
  includeMarginPercentage?: boolean
  withoutCount?: boolean
  includeVerified?: boolean
}
interface InboundInvoiceUpdateParams {
  invoiceId: string,
  include?: string,
  updateContent: any,
  objectScope?: 'both' | 'single' | 'multiple'
}

export default {
  get: ({ include, page, q, perPage, objectScope }: getInboundInvoicesParams) => {
    return api.$get('/inbound_invoices', {
      params: {
        include,
        page,
        q,
        per_page: perPage,
        object_scope: objectScope,
        includeMarkup: true
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  // postInboundInvoice: (invoice: any) => {
  //   return api.$post('/inbound_invoices', invoice, {
  //     headers: {
  //       content_type: 'application/x-www-form-urlencoded'
  //     }
  //   })
  // },
  postInboundInvoice: (params: any, requestBody: any) => {
    return api.$post('/inbound_invoices', {
      ...requestBody
    }, {
      params,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  update: ({ invoiceId, updateContent, include, objectScope }:InboundInvoiceUpdateParams) => {
    return api.$patch(`/inbound_invoices/${invoiceId}`, { invoice: updateContent }, {
      params: {
        objectScope,
        include,
        include_grouped: true
      },
      paramsSerializer: (params:any) => {
        return qs.stringify(params)
      }
    })
  },
  getPreviewPdfById: (id: number, include: string) => {
    return api.$getBuffer(`/inbound_invoices/${id}/preview_pdf`, {
      params: {
        include_grouped: true
      },
      responseType: 'arraybuffer',
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  patchApprove: (invoiceId: any, params: any, objectScope:string, include: string) => {
    return api.$patch(`/inbound_invoices/${invoiceId}/approve`, {
      invoice: {
        status: 'approved',
        show_pay_date_to_vendor: params.show_pay_date_to_vendor,
        pay_date: params.pay_date,
        due_date: params.due_date,
        invoice_notes_attributes: [
          {
            notes: params.note
          }
        ]
      },
      objectScope,
      include_grouped: true,
      include
    })
  },
  patchReject: (invoiceId: any, params: any, objectScope:string, include: string) => {
    return api.$patch(`/inbound_invoices/${invoiceId}/reject`, {
      invoice: {
        status: 'rejected',
        invoice_notes_attributes: [
          { notes: params.note }
        ]
      },
      objectScope,
      include_grouped: true,
      include
    })
  },
  new: (params: any) => apis.$get('/inbound_invoices/new_v2', {
    params,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  newBatch: (params: any, requestBody:any) => api.$post('/inbound_invoices/new_v3', {
    ...requestBody
  }, {

    params,
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  getById: (id: number, params: any) => apis.$get(`/inbound_invoices/${id}`, {
    params: {
      ...params,
      includeMarkup: true
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }),
  inboundInvoicesAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, finderParams, page, perPage } = params
    return apis.$post('/inbound_invoices/autocomplete', {
      field,
      terms,
      predicate,
      finderParams,
      page,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  inboundInvoicesSearch: ({ include, page, q, perPage, objectScope, includeEngagementStatus, workOrderId, includeMarginPercentage, withoutCount, includeVerified }: getInboundInvoicesParams) => {
    return api.$post('/inbound_invoices/search', {
      include,
      page,
      q,
      per_page: perPage,
      object_scope: objectScope,
      includeMarkup: true,
      includeEngagementStatus,
      work_order_id: workOrderId,
      include_grouped: true,
      includeMarginPercentage,
      withoutCount,
      includeVerified
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getInboundSchedulerProfitLossDetail: (param: any) => {
    return api.$get(`/inbound_invoices/${param.id}/scheduler_profit_loss_detail
`, {
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  count: (params: InvoiceParams) => {
    return api.$post('/inbound_invoices/count', {
      ...params,
      paramsSerializer: (params: InvoiceParams) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  invoiceWorkOrderDetails: (params: { ids: number[], objectScope?: string, includeGrouped?: boolean, withoutCount?: boolean }) => {
    return apis.$get('/inbound_invoices/work_order_details', {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  invoiceTripDetails: (params: { ids: number[], objectScope?: string, includeGrouped?: boolean, withoutCount?: boolean }) => {
    return apis.$get('/inbound_invoices/trip_details', {
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  }
}
