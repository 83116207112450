import { reactive } from 'vue'
import repositories from '@/repositories'
import { Task, TradeService } from '@/types/interfaces'
type Trades = {
  loading: boolean,
  list: Array<any>,
  selectedList: Array<any>,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
}
const trades = reactive<Trades>({
  loading: false,
  list: [],
  selectedList: [],
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

interface LoadTradesParams {
  page?: number
  perPage?: number
  q?: any,
  include?: string
}

  // This Interfaces are noe reusable and we are using this to transform services Task Array
  interface WOServiceTask extends Task {
    taskId: number | string
    workOrderTaskId: number | string
  }
  // This Interfaces are noe reusable and we are using this to transform services Task Array
export interface WOService {
    id?: number | string
    description?: string
    name?: string
    revenueCode?: number | string
    tradeServiceId?: number | string
    workOrderTradeId?: number | string
    workOrderTasks?: WOServiceTask[]
    workOrderTrade?: {
      tradeId?: string
      name?: string
    }
  }

export const useTrades = () => {
  const loadTrades = async ({ page, perPage, q }: LoadTradesParams) => {
    try {
      trades.loading = true
      const res = await repositories.trades.get({ page, perPage, q })
      if (!res || !res.trades.length) {
        throw new Error('couldnt load trades')
      }
      if (page === 1) {
        trades.list = res.trades
      }
      trades.list.push([...res.trades])
      trades.meta = { ...res.meta }
      return trades.list
    } catch (err: any) {
      console.log(err)
      return false
    } finally {
      trades.loading = false
    }
  }

  const loadTradesWithoutSaving = async ({ page, perPage, q }: LoadTradesParams) => {
    try {
      const response = await repositories.trades.get({ page, perPage, q })
      return response
    } catch (err: any) {
      console.log(err)
      return {
        trades: [],
        meta: {}
      }
    }
  }

  const appendTradeItem = (item: any) => {
    if (!trades.list.find((eachItem) => eachItem.id === item.id)) {
      trades.list = [{ ...item }, ...trades.list]
    }
    trades.selectedList = trades.list.filter((invoice: any) => invoice.selected)
  }

  const selectTradeItems = (items: any[]) => {
    trades.list.forEach((eachTrade: any) => {
      const { id } = eachTrade
      eachTrade.selected = !!items.find((item: any) => item.id === id)
    })
    trades.selectedList = trades.list.filter((invoice: any) => invoice.selected)
  }

  const updateTrade = () => {
    return ''
  }

  const deleteTrades = async (items: any[]) => {
    trades.loading = true
    await repositories.trades.bulkDestory(items.map(item => item.id)).then(() => {
      trades.loading = false
    }).catch((err: any) => {
      console.log(err)
      trades.loading = false
    })
  }

  const bulkCreateTrades = async (items: any[]) => {
    trades.loading = true
    return await repositories.trades.bulkCreate(items).then(() => {
      trades.loading = false
      return true
    }).catch((err: any) => {
      console.log(err)
      trades.loading = false
      return false
    })
  }

  const getTradeSuggestions = async () => {
    await repositories.trades.getSuggestions()
  }

  const getTrades = async (params: LoadTradesParams) => {
    try {
      const res = await repositories.trades.get(params)
      return {
        data: res.trades,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const getTradeServices = async (params: LoadTradesParams) => {
    try {
      const res = await repositories.trades.getTradeServices(params)
      return {
        data: res.tradeServices,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  // Transform Array to show trade, service & task (Trades > Service > tasks)
  const transformServiceTrades = (services: TradeService[]) => {
    if (!services?.length) {
      return []
    }
    const groupByTrade = services?.reduce((group: any, tradeService: TradeService) => {
      const { tradeId } = tradeService
      group[tradeId] = group[tradeId] ?? []
      group[tradeId].push(tradeService)
      return group
    }, {})
    const tradeServices = [] as Array<any>
    Object.keys(groupByTrade).forEach(function (key) {
      const serviceObj = {
        name: groupByTrade[key][0].tradeList,
        icon: groupByTrade[key][0].trade?.icon || '',
        id: groupByTrade[key][0].trade?.id,
        workOrderTradeId: groupByTrade[key][0].trade?.workOrderTradeId,
        services: groupByTrade[key]
      }
      tradeServices.push(serviceObj)
    })
    return tradeServices
  }

  // Merge wo services array in main service trades array
  const transformWOServicesArrayToTradeServiceArray = (woServiceArray: WOService[]): WOService[] => {
    if (!woServiceArray?.length) {
      return []
    }
    const newServiceArray: WOService[] = []
    woServiceArray.forEach((service: WOService) => {
      const newObject = {
        id: service?.tradeServiceId,
        workOrderTradeServiceId: service.id,
        name: service?.name,
        tradeId: service.workOrderTrade?.tradeId,
        tradeList: service.workOrderTrade?.name,
        tasks: [] as WOServiceTask[]
      }
      service.workOrderTasks?.forEach((task: WOServiceTask) => {
        newObject.tasks.push({
          ...task,
          id: task.taskId,
          workOrderTaskId: task.id
        })
      })
      newServiceArray.push(newObject)
    })
    return newServiceArray
  }

  return {
    trades,
    loadTrades,
    updateTrade,
    deleteTrades,
    appendTradeItem,
    selectTradeItems,
    loadTradesWithoutSaving,
    bulkCreateTrades,
    getTradeSuggestions,
    getTrades,
    getTradeServices,
    transformServiceTrades,
    transformWOServicesArrayToTradeServiceArray
  }
}
