
import {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
} from '@/constants/filters/resources/purchaseOrder'
import { VENDOR_NAME } from '@/constants/filters/resources/vendor'
import { useOutboundPurchaseOrders } from '@/use/purchase-orders/outbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { WORK_ORDER_NUMBER } from '../resources/workOrder'
import { useSession } from '@/use/session'
import { CLIENT } from '@/constants/permissions'
import { ACTION_STATUS_FILTER } from '../resources/action-filter'
import { FilterResources } from '@/types/enums/filter-types'
import { startCase } from 'lodash'
import { CompanyRoleTypes } from '@/types/enums'

const { purchaseOrdersAutoComplete } = useOutboundPurchaseOrders()

export const allOutboundPurchaseOrderFilters = () => {
  const purchaseOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    const { data, nextPage } = await purchaseOrdersAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: params.field === 'action_status' ? startCase(String(item.text)) : item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const { session } = useSession()
  const availableActionFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType !== CLIENT) {
      baseFilters.push(
        ACTION_STATUS_FILTER(
          { pinned: false, isPrimary: false },
          purchaseOrdersAutoCompleteFetchFn,
          { field: 'action_status' },
          FilterResources.PURCHASE_ORDER
        )
      )
    }
    return baseFilters
  }

  return {
    filters: [
      PO_NUMBER(true, { pinned: true, isPrimary: true }, { field: 'po_number' }, purchaseOrdersAutoCompleteFetchFn),
      PO_CUSTOM_NUMBER(true, { pinned: false, isPrimary: false }, { field: 'custom_po_number' }, purchaseOrdersAutoCompleteFetchFn),
      PO_STATUS(CompanyRoleTypes.CLIENT, { pinned: true, isPrimary: true }),
      PO_DATE_OF_ISSUE(true, { pinned: true, isPrimary: true }),
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, purchaseOrdersAutoCompleteFetchFn),
      VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'vendor' }, purchaseOrdersAutoCompleteFetchFn),
      ...availableActionFilters()
    ],
    sortBy: {
      value: 'po_number',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'PO #',
          value: 'po_number'
        },
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Amount',
          value: 'amount_cents'
        }
      ]
    },
    fieldMapping: {
      'purchase-order-number': {
        name: 'po_number',
        field: 'value'
      },
      'purchase-order-custom-number': {
        name: 'custom_po_number',
        field: 'value'
      },
      'purchase-order-status': {
        name: 'calculated_status_code',
        field: 'value'
      },
      'purchase-order-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      },
      'vendor-name': {
        name: 'vendor_name',
        field: 'value'
      },
      'action-status': {
        name: 'cc_required_actions_action',
        field: 'value'
      }
    }
  }
}
