import { debounce } from 'lodash';
import { computed, defineComponent, ref } from 'vue';
import { LOADING_HEADERS } from '@/constants/headers/loading';
import { ACTIONS_COL } from '@/types/interfaces';
export default defineComponent({
  props: {
    firstRow: {
      type: Boolean,
      default: true
    },
    lastRow: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'lg'
    },
    columnDefs: {
      type: Object,
      default: () => LOADING_HEADERS()
    },
    intersectionRef: {
      type: Object,
      default: () => {}
    }
  },
  setup: (props, {
    emit
  }) => {
    const emitIntersect = debounce((entries, observer, isIntersecting) => {
      if (isIntersecting) {
        emit('intersect');
      }
    }, 500);
    const options = computed(() => {
      var _props$intersectionRe;
      // if no intersectionRef is passed it will default to null
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      // root margin doesnt work if there is no overflow https://github.com/w3c/IntersectionObserver/issues/260
      return {
        root: (_props$intersectionRe = props.intersectionRef) === null || _props$intersectionRe === void 0 ? void 0 : _props$intersectionRe.$el,
        // intersection will trigger as true when the item is within 500px of the screen
        rootMargin: '500px',
        threshold: 0
      };
    });
    const listRow = ref(null);
    // note: "columns" template is the one we use for when the columns are not specified
    const loaderColumnNames = computed(() => {
      return props.columnDefs.headers.filter(header => header.visibility[props.size] !== 'hide' && header.templateName !== 'columns').map(header => header.templateName);
    });
    return {
      listRow,
      emitIntersect,
      LOADING_HEADERS,
      loaderColumnNames,
      ACTIONS_COL,
      options
    };
  }
});