import { ERROR, SUCCESS } from '@/constants/icons'
import repositories from '@/repositories'
import { baseGetParams, InvoiceParams } from '@/utils/api'
import { useToasts } from '@/use/toasts'
import { useInvoices } from '@/use/invoices/index'
import { useTransactionResources } from '@/use/transactional-resources'
import { useSession } from '@/use/session'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { useDetailsPanel } from '@/use/details-panel'
import { INBOUND_INVOICE, INVOICE_RESOURCE_TYPE } from '@/constants/resource-types'
import { BROKER } from '@/constants/permissions'
import { useApp } from '@/use/app'
import { InvoiceWorkOrderDetailsResponse } from '@/types/interfaces/api-v2/invoice/work-order-details'
import { InvoiceTripDetailsResponse } from '@/types/interfaces/api-v2/invoice/trip-details'
import { ref } from 'vue'
import { useCompanySettings } from '../company-settings'
import { inboundActionData } from '@/constants/actions-by-resource/invoices/inbound'
interface InboundInvoicePatchApproveParams {
  invoiceId: number | string,
  params?: any,
  include?: string,
  objectScope: string
}
interface InboundInvoicePatchRejectParams {
  invoiceId: number | string,
  params?: any,
  include?: string,
  objectScope: string
}
interface InboundInvoiceUpdateParams {
  invoiceId: string
  updateContent: any,
  include?: string,
  objectScope?: 'both' | 'single' | 'multiple'
}

// TO DO: interface ResourceForInvoiceGeneration is currently in snake case and needs to be converted to camel case this needs to be fixed globally
export interface ResourceForInvoiceGeneration {
  resource_id: number,
  resource_type: string,
  trip_ids: string,
  location_id: number
}

export interface ForwardInvoiceDetails {
  invoiceIds: number[]
  markup: number
  addMarkupToInvoice: boolean
}

/*
  API FUNCTIONS
*/
const getInboundInvoices = async (params: any) => {
  try {
    const res = await repositories.inboundInvoices.get(params)
    return {
      data: res.invoices,
      totalPages: res.meta.totalPages,
      totalCount: res.meta.totalCount
    } as any
  } catch (err) {
    console.log(err)
  }
}

const getInboundInvoicePreviewPdfById = async (id: number, include: string) => {
  try {
    const res = await repositories.inboundInvoices.getPreviewPdfById(id, include)
    return res
  } catch (err: any) {
    console.error(err)
  }
}

const patchApproveInboundInvoice = async ({ invoiceId, params, objectScope, include = '' }: InboundInvoicePatchApproveParams) => {
  try {
    const response = await repositories.inboundInvoices.patchApprove(invoiceId, params, objectScope, include)
    return response
  } catch (error) {
    console.error(error)
    return {}
  }
}
const patchRejectInboundInvoice = async ({ invoiceId, params, objectScope, include = '' }: InboundInvoicePatchRejectParams) => {
  try {
    const response = await repositories.inboundInvoices.patchReject(invoiceId, params, objectScope, include)
    return response
  } catch (error) {
    console.error(error)
    return {}
  }
}
const getInboundInvoicesNew = async (params: baseGetParams) => {
  try {
    const res = await repositories.inboundInvoices.new(params)
    return res
  } catch (err: any) {
    console.error(err)
  }
}

const postInboundInvoices = async (params: any, requestBody: any) => {
  try {
    const res = await repositories.inboundInvoices.postInboundInvoice(params, requestBody)
    return res.invoice
  } catch (err: any) {
    console.error(err)
    return err
  }
}

const updateInboundInvoice = async ({ invoiceId, updateContent, include, objectScope }: InboundInvoiceUpdateParams) => {
  try {
    let res:any
    if (updateContent.grouped) {
      res = await repositories.inboundBatchInvoices.update({ invoiceId, updateContent, include, objectScope })
      const { flattenBatchInvoice } = useInvoices()
      return { invoice: flattenBatchInvoice(res) }
    } else {
      return await repositories.inboundInvoices.update({ invoiceId, updateContent, include, objectScope })
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

const getInboundInvoiceById = async (id: number, params: any) => {
  try {
    const res = await repositories.inboundInvoices.getById(id, params)
    return { data: res.invoice }
  } catch (err: any) {
    console.error(err)
    return { data: false }
  }
}

const postInboundBatchInvoices = async (params: baseGetParams, requestBody:any) => {
  try {
    const res = await repositories.inboundInvoices.newBatch(params, requestBody)
    return res
  } catch (err: any) {
    console.error(err)
  }
}
/*
  UTIL FUNCTIONS
*/

const patchApproveOrRejectResource = async (invoices:any, status: string, statusTxt: string, data:any, patchFn:any, additionalParams:any) => {
  const { addToast } = useToasts()
  const {
    detailsRef,
    canRemoveRowProps,
    removeRows,
    updateRow,
    exportQuery
  } = additionalParams
  const res = await patchFn({
    invoiceId: invoices[0].id,
    params: data,
    objectScope: 'both'
  })
  let toastProps
  if (res.success) {
    toastProps = {
      color: res.success ? 'success' : 'error',
      message: `${res.success ? 'Successfully updated invoice status to ' : 'Unable to update invoice status to '}${statusTxt}`,
      prependIcon: res.success ? SUCCESS : ERROR
    }
    addToast(toastProps)
  }
}

// const outboundStatusMapping = calc()
export const useInboundInvoices = () => {
  const { companyType } = useApp()
  const { invoiceActionModalProps, invoiceActionListenersAndFunctions, flattenBatchInvoice } = useInvoices()
  const { sharedBasicActions } = useTransactionResources()
  const { hasAccess } = useSession()
  const localForwardResourceDetails = ref<ForwardInvoiceDetails>({
    invoiceIds: [],
    markup: 0,
    addMarkupToInvoice: false
  })
  const basicInvoiceInboundActions = [
    ...sharedBasicActions(invoiceActionListenersAndFunctions, false),
    {
      value: 'download',
      bulkConfig: {
      },
      actionFn: (proposals: any[], additionalParams: any) => {
        invoiceActionListenersAndFunctions.download(
          proposals,
          additionalParams,
          false
        )
      },
      noSuccessEmit: true
    },
    {
      value: 'view',
      updateSelectedResource: true,
      actionFn: (invoices: any) => {
        const { openGlobalDetailsPanel } = useDetailsPanel()
        openGlobalDetailsPanel('uc/resource-details', {
          actions: [
            // false - no we are not allowed to foward vendor invoice to client on this nested tab because statuses in payable/pending-approval !include ['approved', 'pafrtially_paid', 'paid']
            ...getApproveRejectActionsForPayableTab(false),
            ...reviewInvoiceInboundAction,
            ...basicInvoiceInboundActions
          ],
          selectedResources: invoices,
          resourceType: INVOICE_RESOURCE_TYPE,
          canDoubleClickToEdit: true,
          outbound: false
        }, '', { }, { })
      },
      noSuccessEmit: true
    },
    // display Change Template on menu options for inbound invoices
    {
      value: 'change-template',
      requiresConfirmation: true,
      updateSelectedResource: false,
      advancedModal: 'uc/change-template',
      dynamicContentProps: { type: INBOUND_INVOICE }
    }
  ]

  const reviewInvoiceInboundAction = [
    {
      value: 'review',
      bulkConfig: {
        type: 'primary'
      },
      updateSelectedResource: true,
      actionFn: (resources: any[], additionalParams: any) => {
        const isBulkSelected = additionalParams.bulkSelectionData.isBulkExclusive
        invoiceActionListenersAndFunctions.review(
          resources,
          additionalParams,
          isBulkSelected
        )
      }
    }
  ]

  /*
  all three props are required in order to return accurate functionality and business logic per invoice dependent nested tabs in either top-level tab : [ Payable, Explore]
  CARLY NOTE: sunset removeOnSuccess and handle ability to remove by the same methodology as modalCondirmationHandler where we need to check the export query and the returned data values
  1) removeOnSuccess -> can I remove rows in uc-table after performing action. in explore nested tabs the answer is no
  2) canForward -> can I forward vendor invoice to client based on:
     2a) status - must be in [approved, partially_paid, paid] aka not payable-pending-approval-tab
     2b) role -> only brokers can forward vendor invoices to client. client will have access to these nest tab uc-tables but should not see the option to 'Foward to Client'
  */

  const getInboundBatchInvoiceById = async (invoiceId: string | number, include: string) => {
    try {
      const res = await repositories.inboundBatchInvoices.getById(Number(invoiceId), { include })
      return { data: flattenBatchInvoice(res) }
    } catch (err: any) {
      console.error(err)
      return { data: false }
    }
  }

  const sharedActionsForPayableTab = (canForward: boolean) => {
    return [
      {
        value: 'approve',
        updateSelectedResource: false,
        requiresConfirmation: true,
        advancedModal: 'invoice/modals/approve',
        exportQueryRequired: true
      },
      {
        value: 'reject',
        requiresConfirmation: true,
        updateSelectedResource: false,
        advancedModal: 'invoice/modals/reject',
        exportQueryRequired: true
      },
      {
        value: 'forward-to-client',
        updateSelectedResource: false,
        requiresConfirmation: true,
        exportQueryRequired: true,
        advancedModal: 'invoice/modals/forward'
      },
      {
        value: 'edit',
        updateSelectedResource: false,
        bulkConfig: {
        },
        actionFn: (resources: any[]) => {
          const { openGlobalDetailsPanel } = useDetailsPanel()
          openGlobalDetailsPanel('uc/resource-details', {
            actions: [
              ...getApproveRejectActionsForPayableTab(false),
              ...reviewInvoiceInboundAction,
              ...basicInvoiceInboundActions
            ],
            selectedResources: resources,
            resourceType: INVOICE_RESOURCE_TYPE,
            canDoubleClickToEdit: false,
            outbound: false
          }, '', { }, { })
        },
        noSuccessEmit: true
      },
      {
        value: 'save-and-approve',
        bulkConfig: {
        }
      },
      {
        value: 'save-and-receive',
        bulkConfig: {
        },
        noSuccessEmit: false
      },
      {
        value: 'skip',
        bulkConfig: {
        }
      },
      {
        value: 'save',
        bulkConfig: {
        }
      }
    ].filter((action:any) => !canForward ? action.value !== 'forward-to-client' : action)
  }

  const getAllInboundActions = () => {
    return [
      ...sharedActionsForPayableTab(companyType.value === BROKER),
      {
        value: 'pay',
        bulkConfig: {
          type: 'primary'
        },
        actionFn: (resources: any[], additionalParams: any) => {
          invoiceActionListenersAndFunctions.pay(resources, additionalParams)
        }
      },
      ...basicInvoiceInboundActions
    ]
  }

  const inboundInvoicesAutoComplete = async (params: AutocompletePayload) => {
    try {
      const res = await repositories.inboundInvoices.inboundInvoicesAutoComplete(params)
      return {
        data: res.results,
        nextPage: res.nextPage
      } as { data: AutocompleteResponse[], nextPage: number }
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const inboundInvoicesSearch = async (params: any) => {
    try {
      const res = await repositories.inboundInvoices.inboundInvoicesSearch(params)
      return {
        data: res.invoices,
        totalPages: res.meta.totalPages,
        totalCount: res.meta.totalCount,
        nextPage: res.meta.nextPage
      } as any
    } catch (err) {
      console.log(err)
    }
  }

  const getApproveRejectActionsForPayableTab = (canForward = false, from = 'payable') => {
    const colorScheme = { reject: 'error', approve: 'success' }
    // check if user have permission we are adding bulk options
    if (hasAccess('can_bulk_approve_and_reject_payable_invoices')) {
      return sharedActionsForPayableTab(canForward).map((actions: { value: string }) =>
        (actions.value === 'reject' || actions.value === 'approve')
          ? {
              ...actions,
              bulkConfig: {
                type: 'menu',
                textColor: colorScheme[actions.value]
              },
              dynamicContentProps: {
                from
              }
            }
          : actions
      )
    } else {
      // false - no we are not allowed to foward vendor invoice to client on this nested tab because statuses in payable/pending-approval !include ['approved', 'pafrtially_paid', 'paid']
      return sharedActionsForPayableTab(canForward)
    }
  }

  const getInboundSchedulerProfitLossDetail = async (param: any) => {
    try {
      let res = await repositories.inboundInvoices.getInboundSchedulerProfitLossDetail(param)
      res = res.profitLossDetail.map((item: any, index:number) => {
        return {
          id: index,
          ...item
        }
      })
      return {
        data: res,
        totalPages: 1,
        totalCount: 1
      } as any
    } catch (err) {
      console.log(err)
      return { data: false }
    }
  }

  const inboundInvoicesCount = async (params: InvoiceParams) => {
    try {
      const res = await repositories.inboundInvoices.count(params)
      return {
        count: res.count
      }
    } catch (err) {
      console.log(err)
      return { count: 0 }
    }
  }

  async function invoiceWorkOrderDetails (params: { ids: number[], objectScope?: string, includeGrouped?: boolean, withoutCount?: boolean }) {
    try {
      const res: { invoices: InvoiceWorkOrderDetailsResponse[] } = await repositories.inboundInvoices.invoiceWorkOrderDetails(params)
      return res
    } catch (err) {
      return { data: false }
    }
  }

  async function invoiceTripDetails (params: { ids: number[], objectScope?: string, includeGrouped?: boolean, withoutCount?: boolean }) {
    try {
      const res: { invoices: InvoiceTripDetailsResponse[] } = await repositories.inboundInvoices.invoiceTripDetails(params)
      return res
    } catch (err) {
      return { data: false }
    }
  }

  const additionalDataDetails = [
    {
      fetchFn: invoiceWorkOrderDetails,
      responseKey: 'invoices',
      dataKey: 'workOrderInfo',
      fetchParams: { objectScope: 'both', includeGrouped: true, withoutCount: true }
    },
    {
      fetchFn: invoiceTripDetails,
      responseKey: 'invoices',
      dataKey: 'tripInfo',
      fetchParams: { objectScope: 'both', includeGrouped: true, withoutCount: true }
    }
  ]

  const getForwardInvoiceAction = (canForward = false, from = 'payable') => {
    if (!canForward) return []

    if (!hasAccess('invoice')) return []

    const { settings } = useCompanySettings()
    if (!settings.value?.data?.invoiceSetting?.addMarkupOrMarginForVendorsInvoice) return []

    return sharedActionsForPayableTab(canForward).map((action: { value: string }) => {
      return action.value === 'forward-to-client'
        ? {
            ...action,
            bulkConfig: { type: 'menu', textColor: 'success' },
            dynamicContentProps: {
              from,
              allowedStatuses: inboundActionData.find((action: any) => action.name === 'Forward to Client').validStatuses,
              numberIdKey: 'invoiceNumber'
            }
          }
        : false
    }).filter((action: any) => action)
  }

  return {
    getInboundInvoices,
    getInboundInvoicePreviewPdfById,
    patchApproveInboundInvoice,
    patchRejectInboundInvoice,
    updateInboundInvoice,
    getInboundInvoicesNew,
    postInboundInvoices,
    getInboundInvoiceById,
    postInboundBatchInvoices,
    getInboundBatchInvoiceById,
    sharedActionsForPayableTab,
    basicInvoiceInboundActions,
    getAllInboundActions,
    inboundInvoicesAutoComplete,
    inboundInvoicesSearch,
    getApproveRejectActionsForPayableTab,
    reviewInvoiceInboundAction,
    getInboundSchedulerProfitLossDetail,
    inboundInvoicesCount,
    additionalDataDetails,
    localForwardResourceDetails,
    getForwardInvoiceAction
  }
}
