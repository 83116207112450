import { FilterResources } from '@/types/enums/filter-types'
import { useAsset } from '@/use/asset'

const { assetsAutoCompleteFetchFn } = useAsset()

const ASSET_CONDITIONS_OPTIONS = [
  {
    id: '0',
    text: 'Excellent',
    value: 'Excellent'
  },
  {
    id: '1',
    text: 'Good',
    value: 'Good'
  },
  {
    id: '2',
    text: 'Average',
    value: 'Average'
  },
  {
    id: '3',
    text: 'Poor',
    value: 'Poor'
  },
  {
    id: '4',
    text: 'Broken',
    value: 'Broken'
  }
]

const ASSET_DECOMMISSION_OPTIONS = [
  {
    id: '0',
    text: 'No',
    value: 'active'
  },
  {
    id: '1',
    text: 'Yes',
    value: 'deleted'
  }
]

const ASSET_TAX_CAPITALIZATION_OPTIONS = [
  {
    id: '0',
    text: 'Yes',
    value: 'true'
  },
  {
    id: '1',
    text: 'No',
    value: 'false'
  }
]

const ASSET_IS_COMPONENT = [
  {
    id: '0',
    text: 'True',
    value: 'true'
  },
  {
    id: '1',
    text: 'False',
    value: 'false'
  }
]

const TAG_NAME = (config = {}, params = { field: 'asset_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Tag Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-tag-name-filter',
      headerText: 'Asset Tag Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['asset_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const TYPE = (config = {}, params = { field: 'equipment_type_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Type',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-type-filter',
      headerText: 'Asset Type',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['equipment_type_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const MANUFACTURER = (config = {}, params = { field: 'manufacturer_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Manufacturer',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-manufacturer-filter',
      headerText: 'Asset Manufacturer',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['manufacturer_name'],
      operator: [{ text: 'Is', value: 'present' }]
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const TAG_ID = (config = {}, params = { field: 'tag_id' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Tag ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'tag-id-filter',
      headerText: 'Tag ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['tag_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const FLOOR = (config = {}, params = { field: 'floor_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Floor',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-floor-filter',
      headerText: 'Asset Floor',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['floor_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const PHYSICAL_LOCATION = (config = {}, params = { field: 'physical_location_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Physical Location',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'physical-location-filter',
      headerText: 'Physical Location',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['physical_location_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const AREA_SERVED = (config = {}, params = { field: 'area_served_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Area Served',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'area-served-filter',
      headerText: 'Area Served',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['area_served_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const CATEGORY = (config = {}, params = { field: 'category_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Category',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-category-filter',
      headerText: 'Asset Category',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['category_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const TRADE = (config = {}, params = { field: 'trade_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Trade',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-trade-filter',
      headerText: 'Asset Trade',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['trade_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const CONDITION = (config = {}, params = { field: 'asset_condition' }) => {
  return {
    text: 'Condition',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-condition-filter',
      headerText: 'Asset Condition',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      params,
      fetchFn: () => {
        return {
          data: ASSET_CONDITIONS_OPTIONS,
          totalCount: ASSET_CONDITIONS_OPTIONS.length,
          totalPages: 1
        }
      },
      searchKeys: ['asset_condition']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const LABELS = (config = {}, params = { field: 'asset_label' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Labels',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-labels-filter',
      headerText: 'Asset Labels',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['asset_label']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const PARENT_TAG_ID = (config = {}, params = { field: 'parent_tag_id' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Parent Tag ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'parent-tag-id-filter',
      headerText: 'Parent Tag ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['parent_tag_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const INSTALL_DATE = (config = {}) => {
  return {
    text: 'Install Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'install-date-filter',
      headerText: 'Install Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const MODEL_NUMBER = (config = {}, params = { field: 'equipment_model_number' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Model Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'model-number-filter',
      headerText: 'Model Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['equipment_model_number']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const SERIAL_NUMBER = (config = {}, params = { field: 'serial_number' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Serial Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'serial-number-filter',
      headerText: 'Serial Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['serial_number']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const MANUFACTURER_DATE = (config = {}) => {
  return {
    text: 'Manufacturer Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'manufacturer-date-filter',
      headerText: 'Manufacturer Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const MATERIAL_WARRANTY_END = (config = {}) => {
  return {
    text: 'Material Warranty End',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'material-warranty-end-filter',
      headerText: 'Material Warranty End',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const LABOR_WARRANTY_END = (config = {}) => {
  return {
    text: 'Labor Warranty End',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'labor-warranty-end-filter',
      headerText: 'Labor Warranty End',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const DECOMMISSIONED_BY_REASON = (config = {}, params = { field: 'decommissioned_reason' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Decommissioned Reason',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'decommissioned-reason-filter',
      headerText: 'Decommissioned Reason',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['decommissioned_reason']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const DECOMMISSIONED_BY_USER = (config = {}, params = { field: 'decommissioned_user' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Decommissioned User',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'decommissioned-user-filter',
      headerText: 'Decommissioned User',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['decommissioned_user']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const DECOMMISSIONED_BY_ORG = (config = {}, params = { field: 'decommissioned_org' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Decommissioned Org',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'decommissioned-org-filter',
      headerText: 'Decommissioned Org',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['decommissioned_org']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const INSTALL_BY_USER = (config = {}, params = { field: 'installed_by_user' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Installed By User',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'installed-by-user-filter',
      headerText: 'Installed By User',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['installed_by_user']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const INSTALL_BY_ORG = (config = {}, params = { field: 'installed_by_org' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Installed By Org',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'installed-by-org-filter',
      headerText: 'Installed By Org',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['installed_by_org']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const EQUIPMENT_ID = (config = {}, params = { field: 'asset_id' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Equipment ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'equipment-id-filter',
      headerText: 'Equipment ID',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['asset_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

// Not Impletemented
const DESCRIPTION = (config = {}, params = { field: 'description' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Description',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-description-filter',
      headerText: 'Description',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['description']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const RECORD_CREATION = (config = {}) => {
  return {
    text: 'Record Creation',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'record-creation-filter',
      headerText: 'Record Creation',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const TAX_CAPITALIZATION_STATUS = (config = {}, params = { field: 'tax_capitalization_status' }) => {
  return {
    text: 'Tax Capitalization Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'tax-capitalization-status-filter',
      headerText: 'Tax Capitalization Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      params,
      fetchFn: () => {
        return {
          data: ASSET_TAX_CAPITALIZATION_OPTIONS,
          totalCount: ASSET_TAX_CAPITALIZATION_OPTIONS.length,
          totalPages: 1
        }
      },
      searchKeys: ['tax_capitalization_status']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const CUSTOMER_EQUIPMENT_ID = (config = {}, params = { field: 'customer_equipment_id' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Customer Equipment ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'customer-equipment-id-filter',
      headerText: 'Customer Equipment ID',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['customer_equipment_id']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const LAST_ACTIVITY_DATE = (config = {}) => {
  return {
    text: 'Last Activity Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'last-activity-date-filter',
      headerText: 'Last Activity Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const FIRST_TAGGED_DATE = (config = {}) => {
  return {
    text: 'First Tagged Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'first-tagged-date-filter',
      headerText: 'First Tagged Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true,
      params: {},
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const PARENT_TAG_NAME = (config = {}, params = { field: 'parent_tag_name' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Parent Tag Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'parent-tag-name-filter',
      headerText: 'Parent Tag Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['parent_tag_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const TAGGED_BY_USER = (config = {}, params = { field: 'tagged_by_user' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Tagged By User',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'tagged-by-user-filter',
      headerText: 'Tagged By User',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['tagged_by_user']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const TAGGED_BY_ORG = (config = {}, params = { field: 'tagged_by_org' }, fetchFn: any = assetsAutoCompleteFetchFn) => {
  return {
    text: 'Tagged By Org',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'tagged-by-org-filter',
      headerText: 'Tagged By Org',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['tagged_by_org']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const DECOMMISSIONED = (config = {}, params = { field: 'status_decommissioned' }) => {
  return {
    text: 'Decommissioned',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'asset-decommissioned-filter',
      headerText: 'Asset Decommissioned',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      params,
      fetchFn: () => {
        return {
          data: ASSET_DECOMMISSION_OPTIONS,
          totalCount: ASSET_DECOMMISSION_OPTIONS.length,
          totalPages: 1
        }
      },
      searchKeys: ['status']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

const IS_COMPONENT_ASSET = (config = {}, params = { field: 'is_component' }) => {
  return {
    text: 'Is Component',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'is-component-filter',
      headerText: 'Is Component',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      params,
      fetchFn: () => {
        return {
          data: ASSET_IS_COMPONENT,
          totalCount: ASSET_IS_COMPONENT.length,
          totalPages: 1
        }
      },
      searchKeys: ['is_component']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.ASSET,
    ...config
  }
}

export {
  TAG_NAME,
  TYPE,
  MANUFACTURER,
  TAG_ID,
  FLOOR,
  PHYSICAL_LOCATION,
  AREA_SERVED,
  CATEGORY,
  TRADE,
  CONDITION,
  LABELS,
  PARENT_TAG_ID,
  INSTALL_DATE,
  MODEL_NUMBER,
  SERIAL_NUMBER,
  MANUFACTURER_DATE,
  MATERIAL_WARRANTY_END,
  LABOR_WARRANTY_END,
  DECOMMISSIONED_BY_REASON,
  DECOMMISSIONED_BY_USER,
  DECOMMISSIONED_BY_ORG,
  INSTALL_BY_USER,
  INSTALL_BY_ORG,
  EQUIPMENT_ID,
  DESCRIPTION,
  RECORD_CREATION,
  TAX_CAPITALIZATION_STATUS,
  CUSTOMER_EQUIPMENT_ID,
  LAST_ACTIVITY_DATE,
  FIRST_TAGGED_DATE,
  PARENT_TAG_NAME,
  TAGGED_BY_USER,
  TAGGED_BY_ORG,
  DECOMMISSIONED,
  IS_COMPONENT_ASSET
}
