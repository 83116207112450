import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', _vm._g({
    staticClass: "uc-chip__wrapper overflow-hidden",
    class: {
      'uc-chip__wrapper--dark': _vm.$vuetify.theme.dark
    },
    on: {
      "mouseenter": function ($event) {
        _vm.canSelect || _vm.clickable;
      },
      "click": function ($event) {
        _vm.canSelect && _setup.toggleSelected();
      }
    }
  }, _vm.$listeners), [_c('uc-tooltip', {
    staticClass: "overflow-hidden",
    attrs: {
      "disabled": (_setup.isEllipses() || _vm.tooltipPosition === 'none') && !_vm.tooltipText,
      "position": _vm.tooltipPosition,
      "text": _vm.tooltipText ? _vm.tooltipText : _vm.text + _vm.appendedText
    }
  }, [_c(VChip, _vm._b({
    class: {
      'borderWidth-0': _vm.noBorder
    },
    style: {
      cursor: _vm.clickable || _vm.canSelect ? 'pointer' : '',
      ..._vm.styleObj,
      background: _vm.backgroundColor
    },
    attrs: {
      "outlined": "",
      "pill": ""
    }
  }, 'v-chip', {
    ..._vm.$attrs,
    color: _vm.color,
    large: _vm.size === 'lg',
    small: _vm.size === 'sm',
    xSmall: _vm.size === 'xs'
  }, false), [_vm.prepend ? _c('uc-icon', {
    staticClass: "mr-1",
    attrs: {
      "value": _vm.prepend,
      "state": _vm.state,
      "size": _vm.iconSize,
      "font-awesome-icon": _vm.fontAwesomeIcon
    }
  }) : _vm._e(), _vm._t("prepend"), _vm.text ? _c('uc-text', {
    ref: "textRef",
    attrs: {
      "size": _setup.getFontSize(_vm.size),
      "type": "body",
      "text": _vm.text,
      "ellipses": "",
      "bold": ""
    }
  }) : _vm._e(), _vm.icon ? _c('uc-icon', {
    ref: "iconRef",
    attrs: {
      "value": _vm.icon,
      "state": _vm.state
    }
  }) : _vm._e(), _vm._t("icon")], 2)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };