/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./input.vue?vue&type=template&id=55c5f5b0&"
import script from "./input.vue?vue&type=script&lang=ts&"
export * from "./input.vue?vue&type=script&lang=ts&"
import style0 from "./input.vue?vue&type=style&index=0&id=55c5f5b0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../shared/vue_ror_frontend/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports