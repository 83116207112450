/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./index.vue?vue&type=template&id=93d3470a&scoped=true&"
import script from "./index.vue?vue&type=script&setup=true&lang=ts&"
export * from "./index.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=93d3470a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../shared/vue_ror_frontend/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93d3470a",
  null
  
)

export default component.exports