import { api } from '@/api'
import { AutocompletePayload } from '@/types/interfaces'
import qs from 'qs'

interface Params {
  include?: string
  q: any
  page: number
  perPage: number
}

export interface CrewWithTeamMembersParams {
  include: string
  page: number
  perPage: number
  q: any
  clientBillingMethod?: string
  locationId?: string
  onlyTeamMembers?: boolean
  onlyPriorityResources?: boolean
  includeCrewsTeamMembers?: boolean
}

const surveyIncludes = 'survey_template, survey_template/questions, survey_template/questions/answer_type, survey_template/questions/answer_choices, observations/answer_choice, observations/answer_choices, observations/question, observations/observation_documents'

export default {
  getCompanies: (params: any) => {
    const parameters: Params = params
    const { include = 'headquarter_address', q, page, perPage } = parameters
    return api.$get('/users/companies', {
      params: {
        include,
        q,
        page,
        per_page: perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  get: (params: any) => api.$get('/companies', {
    params: {
      ...params
    },
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  }),
  getProfile: (params: any) => api.$get('/companies/profile', {
    params: {
      ...params
    },
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  }),
  searchCompanies: (params: any) =>
    api.$get('/companies', {
      params: {
        q: params.q,
        include: params.include,
        skip_user_companies: params?.skipUserCompanies || false,
        can_validate_docu_sign: params?.shouldValidateDocuSignAttributes,
        skip_existing_clients: params?.skipExistingClients || false,
        page: params.page ?? 1,
        per_page: params.perPage ?? 25
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  joinCompany: (id: any) => api.$patch(`/companies/${id}/join_company`, {}),
  claimCompany: (id: any) => api.$patch(`/companies/${id}/join_company`, {}),
  getCompany: (companyId: number) => {
    return api.$get(`/users/companies/${companyId}`, {
      params: {
        include: 'headquarter_address,logo,role'
      }
    })
  },
  getSuggestedSubdomain: (companyName: string) =>
    api.$get('/subdomain_suggestions', {
      params: {
        companyName
      }
    }),
  getCompanyDocumnets: (params: any) =>
    api.$get('/companies/documents', {
      params: {
        q: params.q
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  uploadCompanyDocument: (data: FormData) =>
    api.$post('/companies/documents', data, {
      headers: {
        content_type: 'multipart/form-data'
      }
    }),
  deleteCompanyDocument: (id: number) =>
    api.$delete(`/companies/documents/${id}`),
  createCompany: async (payload: any) => {
    return await api.$post('/companies', payload)
  },
  updateCompanyInfoUsingToken: async ({ token, payload }: any) =>
    await api.$patch(`/companies/update/${token}`, payload),
  getCurrentOnboardStep: (companyId: number) => api.$get(`/companies/profile/${companyId}/onboarding_step`),
  getCurrentOnboardStepWithSubdomain: () => api.$get('/companies/profile/onboarding_step'),
  storeCurrentOnboardStep: (data: any) =>
    api.$patch('/companies/profile/onboarding_step', data, {
      headers: {
        content_type: 'multipart/form-data'
      }
    }),
  getSurvey: (params: any) => api.$get('/surveys', {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  }),
  updateCompanySurvey: async ({ requestHeaders, surveyId, payload }: any) =>
    api.$patch(`/surveys/${surveyId}`, payload, {
      ...requestHeaders
    }),
  getInvitedCompanyDetails: (params: any) =>
    api.$get(`/company/${params.token}`, {
      params: {
        include: params.include
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    }),
  companyDetailsUpdate: (payload: any) => api.$patch('/companies/profile', payload),
  getUsersByCompany: async (companyId: number | string, params: any = {}) => {
    return await api.$get(`/companies/${companyId}/users`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getVendorCompanies: (params: any) => {
    const parameters: Params = params
    const { q, page, perPage } = parameters
    return api.$get('/companies', {
      params: {
        show_self: false,
        check_for_vendor_exists: true,
        q,
        include: params.include,
        show_trades_text: true,
        page,
        perPage
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getCompanyLicense: () => {
    return api.$get('companies/licenses', {
      params: {
        include: 'license_code'
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  addCompanyLicense: (payload: any) => {
    return api.$post('companies/licenses', payload, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editCompanyLicense: (payload: any, licenseId: number) => {
    return api.$patch(`companies/licenses/${licenseId}`, payload, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteCompanyLicense: (licenseId: number) => api.$delete(`companies/licenses/${licenseId}`),
  getLicenseCode: () => {
    return api.$get('license_codes', {
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  getAddressTypeByCompany: async (params: any) => {
    const { companyId, workOrderId } = params
    return await api.$get(`/companies/${companyId}/address_types`, {
      params: {
        work_order_id: workOrderId
      },
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getCompanyComplianceDetails: (params: any) => {
    return api.$get('companies/compliances/details', {
      params: {
        include: surveyIncludes,
        compliance_type: params.complianceType,
        document_type: params.documentType
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  updateComplianceDetails: (surveyObject: any, params: any) => {
    return api.$patch(`companies/compliances/${params.complianceId}`, surveyObject, {
      params: {
        document_type: params.documentType
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  updateCompanyLogo: (params: any) => {
    return api.$patch('companies/profile?include=logo', params, { headers: { content_type: 'multipart/form-data' } })
  },
  updateCompanyProfile: (params: any) => {
    return api.$patch('companies/profile', {
      company: params.payload
    }, {
      params: {
        include: params.include,
        profile_details_required: params.profile_details_required
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  postComplianceDetails: (surveyObject: any, documentType: string) => {
    return api.$post('companies/compliances', surveyObject, {
      params: {
        document_type: documentType
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getAddresses: (params: any) => api.$get('/addresses', {
    params: {
      ...params
    },
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  }),
  getContacts: (params: any) => api.$get('/companies/contacts', {
    params: {
      ...params
    },
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  }),
  addCompanyContact: (params: any) => {
    return api.$patch('companies/profile', params.contactObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getCompanyComplianceList: () => {
    return api.$get('companies/compliances/document_names', {
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  updateCompanyContact: (payload: any) => {
    return api.$patch(`companies/contacts/${payload.contactId}`, payload.contactObject, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteCompanyContact: (params: any) =>
    api.$delete(`companies/contacts/${params.contactId}`),

  getCompanyPaymentMethods: async (companyId: number | string, params: any = {}) => {
    return await api.$get(`/companies/${companyId}/get_company_set_values`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      }
    })
  },
  getCompanyTrades: (params: any) => {
    const parameters: Params = params
    const { include = 'income_account,expense_account', q, page, perPage } = parameters
    return api.$get('trades', {
      params: {
        include,
        page,
        perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  addCompanyTrade: (payload: any) => {
    return api.$post('trades', payload, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editCompanyTrade: (payload: any, tradeId: number) => {
    return api.$patch(`trades/${tradeId}`, payload, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  deleteCompanyTrade: (params: any) => {
    return api.$delete('trades/bulk_destroy', {
      params: {
        ids: params.tradeIds
      }
    })
  },

  getCompanyRegions: (params: any) => {
    const parameters: Params = params
    const { include = 'trades,states', q, page, perPage } = parameters
    return api.$get('regions', {
      params: {
        include,
        page,
        perPage,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  addCompanyRegion: (payload: any) => {
    return api.$post('regions', payload, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  editCompanyRegion: (payload: any, regionId: number) => {
    return api.$patch(`regions/${regionId}`, payload, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },
  getCompanyTradeSuggestions: () => {
    return api.$get('trades/trade_suggestions', {
      params: {},
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
  },
  companiesAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, page } = params
    return api.$post('/companies/autocomplete', {
      field,
      terms,
      predicate,
      page
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getCrewWithTeamMembers: (
    {
      perPage, page, q, include, clientBillingMethod,
      locationId, onlyTeamMembers, onlyPriorityResources,
      includeCrewsTeamMembers
    }: CrewWithTeamMembersParams) => api.$get('/users/crew_with_team_members', {
    params: {
      include,
      page,
      perPage,
      q,
      clientBillingMethod,
      locationId,
      onlyTeamMembers,
      onlyPriorityResources,
      includeCrewsTeamMembers
    },
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })
}
