import { reactive } from 'vue'
import repositories from '@/repositories/index'

const tradeServices:{list: any [], searchList: any[], loading:boolean, searchListLoading: boolean, meta:any, searchListMeta: any} = reactive({
  list: [],
  searchList: [],
  loading: false,
  searchListLoading: false,
  meta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  },
  searchListMeta: {
    currentPage: 1,
    nextPage: 2,
    prevPage: null,
    totalCount: 0,
    totalPages: 0
  }
})

interface LoadTradesParams {
  page: number
  perPage?: number
  q?: any
}

export const useTradeServices = () => {
  const loadTradeServices = async (params: any) => {
    try {
      if (params.search) {
        tradeServices.searchListLoading = true
        const response = await repositories.tradeServices.getTradeServicesList(params)
        tradeServices.searchList = response.tradeServices
        tradeServices.searchListMeta = response.meta
      } else {
        tradeServices.loading = true
        const res = await repositories.tradeServices.getTradeServicesList(params)
        tradeServices.list = res.tradeServices
        tradeServices.meta = res.meta
      }
    } catch (e) {
      console.log(e)
    } finally {
      tradeServices.loading = false
    }
  }
  const bulkDestroyTradeService = async (params: any) => {
    await repositories.tradeServices.deleteTradeServices(params)
  }
  const getTradeServices = async (params: LoadTradesParams) => {
    try {
      const res = await repositories.tradeServices.get(params)
      return {
        data: res.tradeServices,
        totalCount: res.meta.totalCount,
        totalPages: res.meta.totalPages
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const getServicesProvided = async (params: any) => {
    try {
      const res = await repositories.tradeServices.getServicesProvided(params)
      return {
        data: res,
        totalCount: res.length,
        totalPages: 1
      } as any
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const show = async (params: { tradeServiceId: number, include: string }) => {
    try {
      const res = await repositories.tradeServices.show(params)
      return {
        data: res,
        totalCount: res.length,
        totalPages: 1
      } as any
    } catch (err) {
      console.log(err)
      return false
    }
  }
  return {
    tradeServices,
    loadTradeServices,
    bulkDestroyTradeService,
    getTradeServices,
    getServicesProvided,
    show
  }
}
