/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./table-header-selection-indicator.vue?vue&type=template&id=86b097c2&"
import script from "./table-header-selection-indicator.vue?vue&type=script&lang=ts&setup=true&"
export * from "./table-header-selection-indicator.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../../../../shared/vue_ror_frontend/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports