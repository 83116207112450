/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./card-headers.vue?vue&type=template&id=290be8be&scoped=true&"
import script from "./card-headers.vue?vue&type=script&setup=true&lang=ts&"
export * from "./card-headers.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./card-headers.vue?vue&type=style&index=0&id=290be8be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../shared/vue_ror_frontend/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290be8be",
  null
  
)

export default component.exports