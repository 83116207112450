
import {
  PO_NUMBER,
  PO_CUSTOM_NUMBER,
  PO_STATUS,
  PO_DATE_OF_ISSUE
} from '@/constants/filters/resources/purchaseOrder'
import { CLIENT_NAME } from '@/constants/filters/resources/client'
import { useInboundPurchaseOrders } from '@/use/purchase-orders/inbound'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { WORK_ORDER_NUMBER } from '../resources/workOrder'
import { useSession } from '@/use/session'
import { CLIENT } from '@/constants/permissions'
import { FilterResources } from '@/types/enums/filter-types'
import { ACTION_STATUS_FILTER } from '../resources/action-filter'
import { startCase } from 'lodash'
import { CompanyRoleTypes } from '@/types/enums'

const { inboundPurchaseOrdersAutoComplete } = useInboundPurchaseOrders()

const inboundPurchaseOrdersAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await inboundPurchaseOrdersAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: params.field === 'action_status' ? startCase(String(item.text)) : item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const { session } = useSession()
const availableActionFilters = () => {
  const baseFilters: any = []
  if (session.currentCompanyType !== CLIENT) {
    baseFilters.push(
      ACTION_STATUS_FILTER({ pinned: false, isPrimary: false }, inboundPurchaseOrdersAutoCompleteFetchFn, { field: 'action_status' }, FilterResources.PROPOSAL)
    )
  }
  return baseFilters
}

export const allInboundPurchaseOrderFilters = () => {
  return {
    filters: [
      PO_NUMBER(false, { pinned: true, isPrimary: true }, { field: 'po_number' }, inboundPurchaseOrdersAutoCompleteFetchFn),
      PO_CUSTOM_NUMBER(false, { pinned: false, isPrimary: false }, { field: 'custom_po_number' }, inboundPurchaseOrdersAutoCompleteFetchFn),
      PO_STATUS(CompanyRoleTypes.VENDOR, { pinned: true, isPrimary: true }),
      PO_DATE_OF_ISSUE(false, { pinned: false, isPrimary: false }),
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, inboundPurchaseOrdersAutoCompleteFetchFn),
      CLIENT_NAME({ pinned: true, isPrimary: true }, { field: 'client', tab: 'active' }, inboundPurchaseOrdersAutoCompleteFetchFn),
      ...availableActionFilters()
    ],
    sortBy: {
      value: 'po_number',
      direction: {
        text: 'Ascending',
        value: 'asc'
      },
      items: [
        {
          label: 'PO #',
          value: 'po_number'
        },
        {
          label: 'Status',
          value: 'status'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Amount',
          value: 'amount_cents'
        }
      ]
    },
    fieldMapping: {
      'purchase-order-number': {
        name: 'po_number',
        field: 'value'
      },
      'purchase-order-custom-number': {
        name: 'custom_po_number',
        field: 'value'
      },
      'purchase-order-status': {
        name: 'calculated_status_code',
        field: 'value'
      },
      'purchase-order-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'work-order-number': {
        name: 'work_order_id',
        field: 'value'
      },
      'client-name': {
        name: 'company_name',
        field: 'value'
      },
      'purchase-order-stataus': {
        name: 'action_status',
        field: 'value'
      }
    }
  }
}
