import { api } from '@/api'
import { Asset } from '@/types/interfaces'
import qs from 'qs'
import { AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'

interface Download {
  q: any,
  format: string
}
interface assetsParams {
  perPage: number
  page: number
  q?: any
  include?: string,
  assetId?: number | string,
  noteId?: number | string,
  attachmentId?: number | string,
  photoId?: number | string,
  manufactureId?: number | string,
  asset?: Asset,
  manufacturer?: object,
  locationId?: number | string,
  label?: object,
  decommission?: object,
  recommission?: object,
  note?: object,
  equipmentModel?: object,
  attachment?: object,
  photo?: object,
  equipmentPhotoIds?: object,
  download?: Download,
  equipmentLocation?: object
}

export default {
  get: ({ perPage, page, include, q }: assetsParams) => {
    return api.$post('/assets/search', {
      page,
      per_page: perPage,
      include,
      q,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },
  getById: ({ perPage, page, q, include, assetId }: assetsParams) => {
    return api.$get(`/assets/${assetId}`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getModelNumbers: ({ perPage, page, q, include }: assetsParams) => {
    return api.$get('/equipment_models', {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getFloors: ({ perPage, page, q, include }: assetsParams) => {
    return api.$get('floors', {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getPhysicalLocations: ({ perPage, page, q, include, locationId }: assetsParams) => {
    return api.$get(`locations/${locationId}/equipment_locations`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getAreaServed: ({ perPage, page, q, include, locationId }: assetsParams) => {
    return api.$get(`locations/${locationId}/equipment_areas`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getLabels: ({ perPage, page, q, include }: assetsParams) => {
    return api.$get('/company_labels', {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  addLabel: ({ label }: assetsParams) => {
    return api.$post('/company_labels', {
      company_label: label
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  decommissionAsset: ({ decommission, include }: assetsParams) => {
    return api.$patch('/assets/bulk_decommission', decommission, {
      params: {
        include
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  recommissionAsset: ({ recommission, include }: assetsParams) => {
    return api.$patch('/assets/bulk_recommission', recommission, {
      params: {
        include
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  getTCT: ({ perPage, page, include, q }: assetsParams) => {
    return api.$get('/master_tcts', {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getManufacturers: ({ perPage, page, q, include }: assetsParams) => {
    return api.$get('/manufacturers', {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  addManufacturer: ({ manufacturer }: assetsParams) => {
    return api.$post('/manufacturers', {
      manufacturer
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  getAssetNotes: ({ perPage, page, q, include, assetId }: assetsParams) => {
    return api.$get(`/equipment/${assetId}/equipment_notes`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  updateAssetNote: ({ assetId, noteId, note, include }: assetsParams) => {
    return api.$patch(`/equipment/${assetId}/equipment_notes/${noteId}`, note, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  addAssetNote: ({ assetId, note, include }: assetsParams) => {
    return api.$post(`/equipment/${assetId}/equipment_notes`, note, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  deleteAssetNote: ({ assetId, noteId }: assetsParams) => {
    return api.$delete(`/equipment/${assetId}/equipment_notes/${noteId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  getAssetActivities: ({ q, include, assetId }: assetsParams) => {
    return api.$get(`/equipment/${assetId}/equipment_activities`, {
      params: {
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  getAssetAttachments: ({ perPage, page, q, include, assetId }: assetsParams) => {
    return api.$get(`/equipment/${assetId}/equipment_attachments`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  addAssetAttachment: ({ attachment, assetId, include }: assetsParams) => {
    return api.$post(`/equipment/${assetId}/equipment_attachments`, attachment, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  updateAssetAttachment: ({ attachment, assetId, attachmentId, include }: assetsParams) => {
    return api.$patch(`/equipment/${assetId}/equipment_attachments/${attachmentId}`, attachment, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  deleteAssetAttachment: ({ assetId, attachmentId, include }: assetsParams) => {
    return api.$delete(`/equipment/${assetId}/equipment_attachments/${attachmentId}`, {
      params: {
        include
      },
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  getAssetDocumentations: ({ perPage, page, q, include, manufactureId }: assetsParams) => {
    return api.$get(`/assets/${manufactureId}/documentation`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  downloadAssetDocumentation: () => {
    const link = document.createElement('a')
    link.href = window.location.origin + '/api/v2/assets/documentation'
    link.setAttribute(
      'download',
      'sample_template.xlsx'
    )
    link.click()
    link.remove()
  },

  getAssetPhotos: ({ perPage, page, q, include, assetId }: assetsParams) => {
    return api.$get(`/equipment/${assetId}/equipment_photos`, {
      params: {
        page,
        per_page: perPage,
        include,
        q
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  addAssetPhotos: ({ photo, assetId, include }: assetsParams) => {
    return api.$post(`/equipment/${assetId}/equipment_photos`, photo, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  updateAssetPhotos: ({ photo, assetId, photoId, include }: assetsParams) => {
    return api.$patch(`/equipment/${assetId}/equipment_photos/${photoId}`, photo, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  deleteAssetPhoto: ({ assetId, photoId }: assetsParams) => {
    return api.$delete(`/equipment/${assetId}/equipment_photos/${photoId}`, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  updateAsset: ({ assetId, asset, include }: assetsParams) => {
    return api.$patch(`/assets/${assetId}`, {
      equipment: asset,
      include
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  postAsset: ({ asset, include }: assetsParams) => {
    return api.$post('/assets', {
      equipment: asset,
      include
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  addModelNumber: ({ equipmentModel }: assetsParams) => {
    return api.$post('/equipment_models', {
      equipmentModel
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  assetsAutoComplete: (params: AutocompletePayload) => {
    const { field, terms, predicate, tab, page, finderParams, perPage } = params
    return api.$post('/assets/autocomplete', {
      field,
      terms,
      predicate,
      tab,
      page,
      finderParams,
      perPage
    },
    {
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  bulkDownloadAssetsPhotos: ({ assetId, equipmentPhotoIds }: assetsParams) => {
    return api.$getBuffer(`/equipment/${assetId}/equipment_photos/bulk_download?equipment_photo_ids=[${equipmentPhotoIds}]`, {
      params: {
      },
      responseType: 'arraybuffer',
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  updateAssetProfile: ({ assetId, asset, include }: assetsParams) => {
    return api.$patch(`/assets/${assetId}`, asset, {
      params: {
        include
      },
      headers: { content_type: 'multipart/form-data' }
    })
  },

  bulkDownloadAssets: ({ download }: assetsParams) => {
    const link = document.createElement('a')
    const query = qs.stringify({
      q: download?.q
    }, { arrayFormat: 'brackets' })
    link.href = window.location.origin + `/api/v2/assets/download?&${query}&format=${download?.format}`
    link.setAttribute('download', `Assets.${download?.format}`)
    link.click()
    link.remove()
  },

  getParentTagByLocation: ({ perPage, page, include, q, locationId }: assetsParams) => {
    return api.$get('/assets', {
      params: {
        page,
        per_page: perPage,
        include,
        q,
        locationId
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
  },

  addPhysicalLocation: ({ equipmentLocation, locationId }: assetsParams) => {
    return api.$post(`locations/${locationId}/equipment_locations`, {
      equipmentLocation
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  },

  addAreaServed: ({ equipmentLocation, locationId }: assetsParams) => {
    return api.$post(`locations/${locationId}/equipment_areas`, {
      equipmentLocation
    }, {
      paramsSerializer: (params: any) => {
        return qs.stringify(params)
      }
    })
  }
}
