import { useApp } from '@/use/app'
import {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE,
  INVOICE_TRADE,
  INVOICE_TRADE_SERVICE,
  INVOICE_BILLING_METHOD,
  INVOICE_REGION,
  INVOICE_EXTERNAL_REGION,
  INVOICE_RECEIVED_DATE,
  INVOICE_AGREEMENT_CONTRACT_NUMBER,
  INVOICE_AGREEMENT_CUSTOM_NUMBER
} from '@/constants/filters/resources/invoice'

import {
  VENDOR_NAME,
  VENDOR_COINS_ID,
  VENDOR_STATUS
} from '@/constants/filters/resources/vendor'

import {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION,
  SITE_STATE
} from '@/constants/filters/resources/site'
import { BROKER } from '@/constants/permissions'

import { CLIENT_NAME } from '@/constants/filters/resources/client'
import {
  WORK_ORDER_BILLING_METHOD,
  WORK_ORDER_EXTERNAL_REGION,
  WORK_ORDER_NUMBER,
  WORK_ORDER_SERVICE,
  WORK_ORDER_TRADE,
  WORK_ORDER_STATUS,
  WORK_ORDER_CUSTOM_STATUS,
  WORK_ORDER_SERVICE_CHANNEL_CONNECTION,
  WORK_ORDER_CORRIGO_CONNECTION,
  WORK_ORDER_START_DATE,
  WORK_ORDER_EXPIRATION_DATE
} from '../resources/workOrder'
import { TRIP_CREATION_DATE, TRIP_EXPIRATION_DATE } from '../resources/trip'

import { useSession } from '@/use/session'
import { useInboundInvoices } from '@/use/invoices/inbound'
import { AutocompleteResponse, AutocompletePayload } from '@/types/interfaces/api-v2/autocomplete'
import { CompanyRoleTypes } from '@/types/enums'

export const payInvoiceFilters = () => {
  const { companyType } = useApp()

  const { session } = useSession()
  const { inboundInvoicesAutoComplete } = useInboundInvoices()

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }, { field: 'wo_id', invoiceables: false }, inboundInvoicesAutoCompleteFetchFn),
      WORK_ORDER_BILLING_METHOD({ pinned: false, isPrimary: false }, { field: 'billing_method' }, inboundInvoicesAutoCompleteFetchFn),
      WORK_ORDER_STATUS({ pinned: false, isPrimary: false }),
      WORK_ORDER_SERVICE_CHANNEL_CONNECTION({ pinned: false, isPrimary: false }, { field: 'service_channel_connection_id' }, inboundInvoicesAutoCompleteFetchFn),
      WORK_ORDER_CORRIGO_CONNECTION({ pinned: false, isPrimary: false }, { field: 'corrigo_connection_id' }, inboundInvoicesAutoCompleteFetchFn),
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }, { field: 'trade_service' }, inboundInvoicesAutoCompleteFetchFn),
        WORK_ORDER_TRADE({ pinned: false, isPrimary: false }, { field: 'trade' }, inboundInvoicesAutoCompleteFetchFn),
        WORK_ORDER_EXTERNAL_REGION({ pinned: false, isPrimary: false }, inboundInvoicesAutoCompleteFetchFn),
        WORK_ORDER_CUSTOM_STATUS({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableClientsFilters = () => {
    const baseFilters: any = []
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(CLIENT_NAME({ pinned: false, isPrimary: false }, { field: 'locations_client', tab: 'active' }, inboundInvoicesAutoCompleteFetchFn))
    }
    return baseFilters
  }

  const availableSiteFilters = () => {
    const baseFilters: any = [
      SITE_NAME({ pinned: false, isPrimary: false }, { field: 'site', invoiceables: false }, inboundInvoicesAutoCompleteFetchFn),
      // SITE_COUNTRY({ pinned: false, isPrimary: false }),
      // SITE_CITY({ pinned: false, isPrimary: false }),
      // SITE_ZIP({ pinned: false, isPrimary: false }),
      // SITE_ADDRESS({ pinned: false, isPrimary: false })
      SITE_STATE({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        SITE_REGION({ pinned: false, isPrimary: false }, { field: 'site_region', invoiceables: false }, inboundInvoicesAutoCompleteFetchFn)
      )
    }
    return baseFilters
  }

  const inboundInvoicesAutoCompleteFetchFn = async (params: AutocompletePayload) => {
    params.finderParams = { status_in: ['partially_paid', 'approved'] }
    const { data, nextPage } = await inboundInvoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number}
    return {
      data: data.map((item: AutocompleteResponse, index: number) => {
        return {
          id: `${index}-${item.text}`,
          text: item.text,
          value: item.text
        }
      }),
      nextPage
    }
  }

  const availableTripFilters = () => {
    const baseFilters: any = [
      TRIP_CREATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false })
    ]
    return baseFilters
  }

  return {
    filters: [
      INVOICE_NUMBER(companyType.value === BROKER, { pinned: true, isPrimary: true }, { field: 'invoice_number' }, inboundInvoicesAutoCompleteFetchFn),
      INVOICE_CUSTOM_NUMBER(companyType.value === BROKER, { pinned: true, isPrimary: true }, { field: 'custom_invoice_number' }, inboundInvoicesAutoCompleteFetchFn),
      INVOICE_STATUS(CompanyRoleTypes.CLIENT, { pinned: false, isPrimary: false }),
      INVOICE_DATE_OF_ISSUE(companyType.value === BROKER, { pinned: false, isPrimary: false }),
      INVOICE_DUE_DATE(companyType.value === BROKER, { pinned: true, isPrimary: true }),
      INVOICE_RECEIVED_DATE({ pinned: false, isPrimary: true }),
      INVOICE_AGREEMENT_CONTRACT_NUMBER(companyType.value === BROKER, { pinned: false, isPrimary: false }, { field: 'agreement_number' }, inboundInvoicesAutoCompleteFetchFn),
      INVOICE_AGREEMENT_CUSTOM_NUMBER(companyType.value === BROKER, { pinned: false, isPrimary: false }, { field: 'agreement_custom_no' }, inboundInvoicesAutoCompleteFetchFn),
      VENDOR_NAME({ pinned: true, isPrimary: true }, { field: 'vendor' }, inboundInvoicesAutoCompleteFetchFn),
      // VENDOR_COINS_ID({ pinned: false, isPrimary: false })
      ...availableSiteFilters(),
      ...availableClientsFilters(),
      ...availableWorkOrderFilters(),
      ...availableTripFilters()
    ],
    sortBy: {
      value: 'date_of_issue',
      direction: {
        text: 'Descending',
        value: 'desc'
      },
      items: [
        {
          label: 'Due Date',
          value: 'due_date'
        },
        {
          label: 'Date of Issue',
          value: 'date_of_issue'
        },
        {
          label: 'Invoice #',
          value: 'short_invoice_number'
        },
        {
          label: 'Invoice Received Date',
          value: 'draft_sent_at'
        },
        {
          label: 'Amount Due',
          value: 'payable_balance_cents'
        }
      ]
    },
    fieldMapping: {
      'invoice-number': {
        name: 'short_invoice_number',
        field: 'text'
      },
      'invoice-custom-number': {
        name: 'custom_invoice_number',
        field: 'value'
      },
      'invoice-work-order-number': {
        name: 'resource_id',
        field: 'value',
        inject: [
          {
            name: 'resource_type',
            values: ['WorkOrder'],
            operator: 'in'
          }
        ]
      },
      'invoice-status': {
        name: 'calculated_status_code',
        field: 'value'
      },
      'invoice-date-of-issue': {
        name: 'date_of_issue',
        field: 'value'
      },
      'invoice-due-date': {
        name: 'due_date',
        field: 'value'
      },
      'invoice-received-date': {
        name: 'draft_sent_at',
        field: 'value'
      },
      'schedule-service-id': {
        name: 'agreement_contract_id',
        field: 'value'
      },
      'schedule-service-custom-id': {
        name: 'agreement_contract_custom_id',
        field: 'value'
      },
      'vendor-name': {
        name: 'vendor_name',
        field: 'value'
      },
      'vendor-coins-id': {
        name: 'coins_id',
        field: 'value'
      },
      'site-name': {
        name: 'locations_name',
        field: 'value'
      },
      'site-country': {
        name: 'locations_address_country',
        field: 'value'
      },
      'site-city': {
        name: 'locations_address_city',
        field: 'value'
      },
      'site-zip': {
        name: 'locations_address_zip_code',
        field: 'value'
      },
      'site-address': {
        name: 'locations_address_formatted_address',
        field: 'value'
      },
      'site-region': {
        name: 'locations_cc_territory_name',
        field: 'text'
      },
      'client-name': {
        name: 'locations_company_name',
        field: 'value'
      },
      'work-order-number': {
        linkingTables: {
          clients: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: ['work_order_id', 'invoice_trips_trip_work_order_id'],
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'id',
          weatherEventsName: 'id'
        },
        rowTypesToQuery: [
          'clients',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-service': {
        name: 'work_order_work_order_trade_services_trade_service_name',
        field: 'value'
      },
      'work-order-trade': {
        name: 'work_order_work_order_trades_trade_name',
        field: 'value'
      },
      'work-order-billing-method': {
        name: 'work_order_work_order_type',
        field: 'value'
      },
      'external-region': {
        name: 'work_order_vendor_client_agreements_external_region',
        field: 'value'
      },
      'work-order-status': {
        name: 'work_order_work_order_status_name',
        field: 'text'
      },
      trade: {
        name: 'work_order_work_order_trades_trade_name',
        field: 'text'
      },
      'trade-service': {
        name: 'work_order_work_order_trade_services_trade_service_name',
        field: 'text'
      },
      'billing-method': {
        name: 'work_order_trips_vendor_billing_method',
        field: 'text'
      },
      region: {
        name: 'locations_territories_name',
        field: 'text'
      },
      'work-order-custom-status': {
        name: 'work_order_work_order_status_name',
        field: 'value'
      },
      'service-channel-connection': {
        name: 'work_order_trips_service_channel_connection_external_id',
        field: 'value'
      },
      'corrigo-connection': {
        name: 'work_order_corrigo_connection_external_id',
        field: 'value'
      },
      'work-order-start-date': {
        name: 'work_order_created_at',
        field: 'value'
      },
      'work-order-expiration-date': {
        name: 'work_order_expiration_date',
        field: 'value'
      },
      'trip-creation-date': {
        name: 'trips_start_date',
        field: 'value'
      },
      'trip-expiration-date': {
        name: 'trips_expiration_date',
        field: 'value'
      }
    }
  }
}
