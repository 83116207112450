import { useClients } from '@/use/clients'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { FilterResources } from '@/types/enums/filter-types'

const { getClients, getClientAddresses, getClientLocations, getClientLocationsAddress } = useClients()

const { clientsAutoComplete } = useClients()

const clientsAutoCompleteFetchFn = async (params: AutocompletePayload) => {
  const { data, nextPage } = await clientsAutoComplete({ ...params }) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const CLIENT_NAME = (config = {}, params = { field: 'name', tab: 'active' }, fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Name',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-name-filter',
      headerText: 'Client Name',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['client_name']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_NO_OF_SITES = (config = {}, tab = 'active') => {
  return {
    text: 'Number of Sites',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-no-of-sites-filter',
      headerText: 'Number of Sites',
      size: 'md',
      contentType: 'number-picker',
      rowType: 'text',
      showHeaders: true,
      params: { field: 'location_count', tab },
      searchKeys: ['location_count']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_COUNTRY = (config = {}, params = { field: 'country', tab: 'active' }, fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Country',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-country-filter',
      headerText: 'Client Country',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['country']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_STATE = (config = {}, params = { field: 'state', tab: 'active' }, fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'State',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-state-filter',
      headerText: 'Client State',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['state']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_CITY = (config = {}, params = { field: 'city', tab: 'active' }, fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'City',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-city-filter',
      headerText: 'Client City',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['city']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_ZIP = (config = {}, params = { field: 'zip_code', tab: 'active' }, fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Zip',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-zip-filter',
      headerText: 'Client Zip',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['zip_code']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_ADDRESS = (config = {}, params = { field: 'address1', tab: 'active' }, fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Address',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-address-filter',
      headerText: 'Client Address',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['address1']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_NAME = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-filter',
      headerText: 'Client Location',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'location', tab },
      searchKeys: ['location']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_IN_CARE_OF = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location In Care Of',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-in-care-of-filter',
      headerText: 'Client Location In Care Of',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'in_care_of', tab },
      searchKeys: ['in_care_of']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_COUNTRY = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location Country',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-country-filter',
      headerText: 'Client Location Country',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'location_country', tab },
      searchKeys: ['location_country']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_STATE = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location State',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-state-filter',
      headerText: 'Client Location State',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'location_state', tab },
      searchKeys: ['location_state']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_CITY = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location City',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-city-filter',
      headerText: 'Client Location City',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'location_city', tab },
      searchKeys: ['location_city']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_ZIP = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location Zip',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-zip-filter',
      headerText: 'Client Location Zip',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'location_zip_code', tab },
      searchKeys: ['location_zip_code']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

const CLIENT_LOCATION_ADDRESS = (config = {}, tab = 'active', fetchFn: any = clientsAutoCompleteFetchFn) => {
  return {
    text: 'Location Address',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'client-location-address-filter',
      headerText: 'Client Location Address',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params: { field: 'location_address1', tab },
      searchKeys: ['location_address1']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.CLIENT,
    ...config
  }
}

export {
  CLIENT_NAME,
  CLIENT_NO_OF_SITES,
  CLIENT_COUNTRY,
  CLIENT_STATE,
  CLIENT_CITY,
  CLIENT_ZIP,
  CLIENT_ADDRESS,
  CLIENT_LOCATION_NAME,
  CLIENT_LOCATION_IN_CARE_OF,
  CLIENT_LOCATION_COUNTRY,
  CLIENT_LOCATION_STATE,
  CLIENT_LOCATION_CITY,
  CLIENT_LOCATION_ZIP,
  CLIENT_LOCATION_ADDRESS
}
