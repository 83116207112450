/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./store-image-display.vue?vue&type=template&id=2ecf5ef4&"
import script from "./store-image-display.vue?vue&type=script&lang=js&"
export * from "./store-image-display.vue?vue&type=script&lang=js&"
import style0 from "./store-image-display.vue?vue&type=style&index=0&id=2ecf5ef4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../shared/vue_ror_frontend/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports