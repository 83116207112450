import companies from './companies'
import vendors from './vendors'
import invoices from './invoices'
import batchInvoices from './outbound-batch-invoices'
import session from './session'
import inboundInvoices from './inbound-invoices'
import inboundBatchInvoices from './inbound-batch-invoices'
import inboundPurchaseOrders from './inbound-purchase-orders'
import paymentMethods from './paymentMethods'
import outboundWorkOrders from './outbound-workorders'
import inboundWorkOrders from './inbound-work-orders'
import todoWorkOrders from './todo-workorders'
import workOrders from './work-orders'
import trips from './trips'
import items from './items'
import itemTypes from './item-types'
import departments from './departments'
import userCompany from './user_company'
import signup from './signup'
import proposals from './proposals'
import locations from './locations'
import netTerms from './net_terms'
import taxes from './taxes'
import taxCodes from './tax_codes'
import materialMarkup from './material_markup'
import previewPdf from './preview_pdf'
import clients from './clients'
import clientsLocations from './clients/locations'
import companySettings from './company-settings'
import invoiceSettings from './company-settings/invoices'
import paymentAccounts from './payment-accounts'
import postPayment from './post_payment'
import makePayment from './makePayment'
import dashboard from './dashboard'
import invoicesWithId from './invoiceWithId'
import purchaseOrders from './purchase-orders'
import trades from './trades'
import crews from './crews'
import companyUsers from './company_users'
import roles from './roles'
import payments from './payments'
import companyMaterials from './company_materials'
import sites from './sites'
import tradesmen from './tradesmen'
import workOrderServices from './work-order-services'
import workOrderContacts from './work-order/contacts'
import workOrderStatuses from './work_order_statuses'
import tradeServices from './trade_services'
import ledgerAccounts from './ledger_accounts'
import laborRates from './labor_rates'
import serviceRates from './service_rates'
import taskRates from './task_rates'
import equipmentTaskRates from './equipment_task_rates'
import regions from './regions'
import workOrderAttachments from './work_order_attachments'
import createCompanyProfile from './createCompanyProfile'
import packages from './packages'
import chargebeeSubscriptions from './chargebeeSubscriptions'
import usersCompanies from './users-companies'
import companyClassifications from './company-classifications'
import packageCategories from './package-categories'
import appStore from './app-store'
import callTypes from './call-types'
import priorities from './priorities'
import activities from './activities'
import backgroundTasks from './background-tasks'
import exports from './exports'
import notes from './notes'
import attendances from './attendances'
import attachments from './attachments'
import stripeAccount from './stripeAccount'
import billPayments from './bill-payments'
import surveyTemplates from './survey-templates'
import surveys from './surveys'
import weatherEvents from './weather-events'
import agreements from './agreements'
import inboundProposals from './inbound-proposals'
import contacts from './contacts'
import chargebee from './chargebee'
import alerts from './alerts'
import filterTemplates from './filter-templates'
import bulkActions from './bulk-actions'
import mentions from './mentions'
import workOrderReports from './reports/work-orders'
import generalSettings from './general-settings'
import requirements from './company-settings/compliancy/vendor-compliancy/requirements'
import compliance from './compliance'
import materials from './work-order/materials'
import tasks from './tasks'
import workOrdersInvoiceStatusValidators from './settings/work-orders/invoice-statuses'
import workOrdersStatusChangeQuestions from './work-orders/status-change-questions'
import draftTrips from './draft-trips'
import users from './users'
import territories from './territories'
import travelTimeAdjustments from './travel-time-adjustments'
import serviceAdjustments from './service-adjustments'
import timecard from './timecard'
import tradesmenTrips from './tradesmen-trips'
import previewTemplates from './preview_templates'
import accountMargin from './settings/accounting/account-margin'
import chat from './chat'
import conferenceCall from './conference-call'
import weatherWorks from './weather-works'
import workOrderSettings from './company-settings/work-orders'
import assets from './assets'
import vendorInvoiceRejectionForm from './company-settings/vendor-invoice-rejection-form'
import actionFilters from './action-filters'

export default {
  companies,
  agreements,
  surveyTemplates,
  surveys,
  backgroundTasks,
  session,
  exports,
  vendors,
  priorities,
  outboundWorkOrders,
  inboundWorkOrders,
  todoWorkOrders,
  trips,
  items,
  itemTypes,
  departments,
  inboundInvoices,
  inboundBatchInvoices,
  inboundPurchaseOrders,
  invoices,
  batchInvoices,
  userCompany,
  paymentMethods,
  callTypes,
  signup,
  proposals,
  invoicesWithId,
  locations,
  netTerms,
  taxes,
  taxCodes,
  materialMarkup,
  previewPdf,
  clients,
  clientsLocations,
  companySettings,
  invoiceSettings,
  paymentAccounts,
  postPayment,
  makePayment,
  dashboard,
  purchaseOrders,
  trades,
  crews,
  companyUsers,
  roles,
  payments,
  ledgerAccounts,
  laborRates,
  serviceRates,
  taskRates,
  equipmentTaskRates,
  companyMaterials,
  sites,
  tradesmen,
  workOrderServices,
  workOrderContacts,
  tradeServices,
  regions,
  workOrderAttachments,
  workOrderStatuses,
  createCompanyProfile,
  packages,
  chargebeeSubscriptions,
  usersCompanies,
  companyClassifications,
  packageCategories,
  appStore,
  workOrders,
  activities,
  notes,
  attendances,
  attachments,
  stripeAccount,
  billPayments,
  weatherEvents,
  inboundProposals,
  contacts,
  chargebee,
  alerts,
  filterTemplates,
  bulkActions,
  mentions,
  workOrderReports,
  generalSettings,
  previewTemplates,
  requirements,
  compliance,
  materials,
  tasks,
  workOrdersInvoiceStatusValidators,
  workOrdersStatusChangeQuestions,
  draftTrips,
  users,
  territories,
  travelTimeAdjustments,
  serviceAdjustments,
  timecard,
  tradesmenTrips,
  weatherWorks,
  workOrderSettings,
  chat,
  conferenceCall,
  accountMargin,
  vendorInvoiceRejectionForm,
  assets,
  actionFilters
}
